#cloud-intro{
  position: relative;
  height: 100%;
  background: url("./assets/clouds/clouds1000.png");
  background: url("./assets/clouds/clouds1000.png") 0 200px,
              url("./assets/clouds/clouds1200_2.png") 0 300px,
              url("./assets/clouds/clouds1200_1.png") 100px 250px;
	animation: wind 20s linear infinite;
}
@keyframes wind{
  0% {
    background-position: 0 200px, 0 300px, 100px 250px;
  }
  100% {
    background-position: 1000px 200px, 1200px 300px, 1100px 250px;
  }

}
.button-join{
  -webkit-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
  font-family: sans-serif;
}
.button-join:hover {
  -webkit-transform: scale(1.01) translate3d(0, 0, 0);
          transform: scale(1.01) translate3d(0, 0, 0);
  box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.2), 6px 12px 12px 0 rgba(0, 0, 0, 0.25);
  background-color: #e74c3c;
  font-weight: bold;
  color: white;
}
.button-check{
  -webkit-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
  font-family: sans-serif;
  background-color: #69C7FF;
}
.button-check:hover {
  -webkit-transform: scale(1.01) translate3d(0, 0, 0);
          transform: scale(1.01) translate3d(0, 0, 0);
  box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.2), 6px 12px 12px 0 rgba(0, 0, 0, 0.25);
  background-color: green;
  font-weight: bold;
  color: white;
}
.reflection-container {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    transform-style: preserve-3d;
    perspective: 1000px;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 5px;
  }
  .reflection-container .reflection-content {
    width: 100%;
    /* background: #000 url(https://unsplash.it/800/800/?random); */
    background-size: cover;
    background-position: center;
    transform: rotateX(0) rotateY(0);
    pointer-events: none;
    transition: 100ms linear transform;
    overflow: hidden;
  }
  .reflection-container .reflection-content:before {
    content: '';
    position: absolute;
    width: 200%;
    height: 200%;
    left: -50%;
    top: -50%;
    background: linear-gradient(rgba(255, 255, 255, 0.1), rgba(0, 0, 0, 0.1));
  }
  .reflection-container .reflection-grid-cell {
    position: absolute;
    z-index: 1;
    width: 10%;
    height: 10%;
  }
  .reflection-container .reflection-grid-cell-1 {
    top: 0%;
    left: 0%;
  }
  .reflection-container .reflection-grid-cell-1:hover ~ .reflection-content {
    transform: rotateX(20deg) rotateY(-20deg);
  }
  .reflection-container .reflection-grid-cell-1:hover ~ .reflection-content:before {
    transform: translateY(20%);
  }
  .reflection-container .reflection-grid-cell-2 {
    top: 0%;
    left: 10%;
  }
  .reflection-container .reflection-grid-cell-2:hover ~ .reflection-content {
    transform: rotateX(20deg) rotateY(-15deg);
  }
  .reflection-container .reflection-grid-cell-2:hover ~ .reflection-content:before {
    transform: translateY(20%);
  }
  .reflection-container .reflection-grid-cell-3 {
    top: 0%;
    left: 20%;
  }
  .reflection-container .reflection-grid-cell-3:hover ~ .reflection-content {
    transform: rotateX(20deg) rotateY(-10deg);
  }
  .reflection-container .reflection-grid-cell-3:hover ~ .reflection-content:before {
    transform: translateY(20%);
  }
  .reflection-container .reflection-grid-cell-4 {
    top: 0%;
    left: 30%;
  }
  .reflection-container .reflection-grid-cell-4:hover ~ .reflection-content {
    transform: rotateX(20deg) rotateY(-5deg);
  }
  .reflection-container .reflection-grid-cell-4:hover ~ .reflection-content:before {
    transform: translateY(20%);
  }
  .reflection-container .reflection-grid-cell-5 {
    top: 0%;
    left: 40%;
  }
  .reflection-container .reflection-grid-cell-5:hover ~ .reflection-content {
    transform: rotateX(20deg) rotateY(0deg);
  }
  .reflection-container .reflection-grid-cell-5:hover ~ .reflection-content:before {
    transform: translateY(20%);
  }
  .reflection-container .reflection-grid-cell-6 {
    top: 0%;
    left: 50%;
  }
  .reflection-container .reflection-grid-cell-6:hover ~ .reflection-content {
    transform: rotateX(20deg) rotateY(5deg);
  }
  .reflection-container .reflection-grid-cell-6:hover ~ .reflection-content:before {
    transform: translateY(20%);
  }
  .reflection-container .reflection-grid-cell-7 {
    top: 0%;
    left: 60%;
  }
  .reflection-container .reflection-grid-cell-7:hover ~ .reflection-content {
    transform: rotateX(20deg) rotateY(10deg);
  }
  .reflection-container .reflection-grid-cell-7:hover ~ .reflection-content:before {
    transform: translateY(20%);
  }
  .reflection-container .reflection-grid-cell-8 {
    top: 0%;
    left: 70%;
  }
  .reflection-container .reflection-grid-cell-8:hover ~ .reflection-content {
    transform: rotateX(20deg) rotateY(15deg);
  }
  .reflection-container .reflection-grid-cell-8:hover ~ .reflection-content:before {
    transform: translateY(20%);
  }
  .reflection-container .reflection-grid-cell-9 {
    top: 0%;
    left: 80%;
  }
  .reflection-container .reflection-grid-cell-9:hover ~ .reflection-content {
    transform: rotateX(20deg) rotateY(20deg);
  }
  .reflection-container .reflection-grid-cell-9:hover ~ .reflection-content:before {
    transform: translateY(20%);
  }
  .reflection-container .reflection-grid-cell-10 {
    top: 0%;
    left: 90%;
  }
  .reflection-container .reflection-grid-cell-10:hover ~ .reflection-content {
    transform: rotateX(20deg) rotateY(25deg);
  }
  .reflection-container .reflection-grid-cell-10:hover ~ .reflection-content:before {
    transform: translateY(20%);
  }
  .reflection-container .reflection-grid-cell-11 {
    top: 10%;
    left: 0%;
  }
  .reflection-container .reflection-grid-cell-11:hover ~ .reflection-content {
    transform: rotateX(15deg) rotateY(-20deg);
  }
  .reflection-container .reflection-grid-cell-11:hover ~ .reflection-content:before {
    transform: translateY(15%);
  }
  .reflection-container .reflection-grid-cell-12 {
    top: 10%;
    left: 10%;
  }
  .reflection-container .reflection-grid-cell-12:hover ~ .reflection-content {
    transform: rotateX(15deg) rotateY(-15deg);
  }
  .reflection-container .reflection-grid-cell-12:hover ~ .reflection-content:before {
    transform: translateY(15%);
  }
  .reflection-container .reflection-grid-cell-13 {
    top: 10%;
    left: 20%;
  }
  .reflection-container .reflection-grid-cell-13:hover ~ .reflection-content {
    transform: rotateX(15deg) rotateY(-10deg);
  }
  .reflection-container .reflection-grid-cell-13:hover ~ .reflection-content:before {
    transform: translateY(15%);
  }
  .reflection-container .reflection-grid-cell-14 {
    top: 10%;
    left: 30%;
  }
  .reflection-container .reflection-grid-cell-14:hover ~ .reflection-content {
    transform: rotateX(15deg) rotateY(-5deg);
  }
  .reflection-container .reflection-grid-cell-14:hover ~ .reflection-content:before {
    transform: translateY(15%);
  }
  .reflection-container .reflection-grid-cell-15 {
    top: 10%;
    left: 40%;
  }
  .reflection-container .reflection-grid-cell-15:hover ~ .reflection-content {
    transform: rotateX(15deg) rotateY(0deg);
  }
  .reflection-container .reflection-grid-cell-15:hover ~ .reflection-content:before {
    transform: translateY(15%);
  }
  .reflection-container .reflection-grid-cell-16 {
    top: 10%;
    left: 50%;
  }
  .reflection-container .reflection-grid-cell-16:hover ~ .reflection-content {
    transform: rotateX(15deg) rotateY(5deg);
  }
  .reflection-container .reflection-grid-cell-16:hover ~ .reflection-content:before {
    transform: translateY(15%);
  }
  .reflection-container .reflection-grid-cell-17 {
    top: 10%;
    left: 60%;
  }
  .reflection-container .reflection-grid-cell-17:hover ~ .reflection-content {
    transform: rotateX(15deg) rotateY(10deg);
  }
  .reflection-container .reflection-grid-cell-17:hover ~ .reflection-content:before {
    transform: translateY(15%);
  }
  .reflection-container .reflection-grid-cell-18 {
    top: 10%;
    left: 70%;
  }
  .reflection-container .reflection-grid-cell-18:hover ~ .reflection-content {
    transform: rotateX(15deg) rotateY(15deg);
  }
  .reflection-container .reflection-grid-cell-18:hover ~ .reflection-content:before {
    transform: translateY(15%);
  }
  .reflection-container .reflection-grid-cell-19 {
    top: 10%;
    left: 80%;
  }
  .reflection-container .reflection-grid-cell-19:hover ~ .reflection-content {
    transform: rotateX(15deg) rotateY(20deg);
  }
  .reflection-container .reflection-grid-cell-19:hover ~ .reflection-content:before {
    transform: translateY(15%);
  }
  .reflection-container .reflection-grid-cell-20 {
    top: 10%;
    left: 90%;
  }
  .reflection-container .reflection-grid-cell-20:hover ~ .reflection-content {
    transform: rotateX(15deg) rotateY(25deg);
  }
  .reflection-container .reflection-grid-cell-20:hover ~ .reflection-content:before {
    transform: translateY(15%);
  }
  .reflection-container .reflection-grid-cell-21 {
    top: 20%;
    left: 0%;
  }
  .reflection-container .reflection-grid-cell-21:hover ~ .reflection-content {
    transform: rotateX(10deg) rotateY(-20deg);
  }
  .reflection-container .reflection-grid-cell-21:hover ~ .reflection-content:before {
    transform: translateY(10%);
  }
  .reflection-container .reflection-grid-cell-22 {
    top: 20%;
    left: 10%;
  }
  .reflection-container .reflection-grid-cell-22:hover ~ .reflection-content {
    transform: rotateX(10deg) rotateY(-15deg);
  }
  .reflection-container .reflection-grid-cell-22:hover ~ .reflection-content:before {
    transform: translateY(10%);
  }
  .reflection-container .reflection-grid-cell-23 {
    top: 20%;
    left: 20%;
  }
  .reflection-container .reflection-grid-cell-23:hover ~ .reflection-content {
    transform: rotateX(10deg) rotateY(-10deg);
  }
  .reflection-container .reflection-grid-cell-23:hover ~ .reflection-content:before {
    transform: translateY(10%);
  }
  .reflection-container .reflection-grid-cell-24 {
    top: 20%;
    left: 30%;
  }
  .reflection-container .reflection-grid-cell-24:hover ~ .reflection-content {
    transform: rotateX(10deg) rotateY(-5deg);
  }
  .reflection-container .reflection-grid-cell-24:hover ~ .reflection-content:before {
    transform: translateY(10%);
  }
  .reflection-container .reflection-grid-cell-25 {
    top: 20%;
    left: 40%;
  }
  .reflection-container .reflection-grid-cell-25:hover ~ .reflection-content {
    transform: rotateX(10deg) rotateY(0deg);
  }
  .reflection-container .reflection-grid-cell-25:hover ~ .reflection-content:before {
    transform: translateY(10%);
  }
  .reflection-container .reflection-grid-cell-26 {
    top: 20%;
    left: 50%;
  }
  .reflection-container .reflection-grid-cell-26:hover ~ .reflection-content {
    transform: rotateX(10deg) rotateY(5deg);
  }
  .reflection-container .reflection-grid-cell-26:hover ~ .reflection-content:before {
    transform: translateY(10%);
  }
  .reflection-container .reflection-grid-cell-27 {
    top: 20%;
    left: 60%;
  }
  .reflection-container .reflection-grid-cell-27:hover ~ .reflection-content {
    transform: rotateX(10deg) rotateY(10deg);
  }
  .reflection-container .reflection-grid-cell-27:hover ~ .reflection-content:before {
    transform: translateY(10%);
  }
  .reflection-container .reflection-grid-cell-28 {
    top: 20%;
    left: 70%;
  }
  .reflection-container .reflection-grid-cell-28:hover ~ .reflection-content {
    transform: rotateX(10deg) rotateY(15deg);
  }
  .reflection-container .reflection-grid-cell-28:hover ~ .reflection-content:before {
    transform: translateY(10%);
  }
  .reflection-container .reflection-grid-cell-29 {
    top: 20%;
    left: 80%;
  }
  .reflection-container .reflection-grid-cell-29:hover ~ .reflection-content {
    transform: rotateX(10deg) rotateY(20deg);
  }
  .reflection-container .reflection-grid-cell-29:hover ~ .reflection-content:before {
    transform: translateY(10%);
  }
  .reflection-container .reflection-grid-cell-30 {
    top: 20%;
    left: 90%;
  }
  .reflection-container .reflection-grid-cell-30:hover ~ .reflection-content {
    transform: rotateX(10deg) rotateY(25deg);
  }
  .reflection-container .reflection-grid-cell-30:hover ~ .reflection-content:before {
    transform: translateY(10%);
  }
  .reflection-container .reflection-grid-cell-31 {
    top: 30%;
    left: 0%;
  }
  .reflection-container .reflection-grid-cell-31:hover ~ .reflection-content {
    transform: rotateX(5deg) rotateY(-20deg);
  }
  .reflection-container .reflection-grid-cell-31:hover ~ .reflection-content:before {
    transform: translateY(5%);
  }
  .reflection-container .reflection-grid-cell-32 {
    top: 30%;
    left: 10%;
  }
  .reflection-container .reflection-grid-cell-32:hover ~ .reflection-content {
    transform: rotateX(5deg) rotateY(-15deg);
  }
  .reflection-container .reflection-grid-cell-32:hover ~ .reflection-content:before {
    transform: translateY(5%);
  }
  .reflection-container .reflection-grid-cell-33 {
    top: 30%;
    left: 20%;
  }
  .reflection-container .reflection-grid-cell-33:hover ~ .reflection-content {
    transform: rotateX(5deg) rotateY(-10deg);
  }
  .reflection-container .reflection-grid-cell-33:hover ~ .reflection-content:before {
    transform: translateY(5%);
  }
  .reflection-container .reflection-grid-cell-34 {
    top: 30%;
    left: 30%;
  }
  .reflection-container .reflection-grid-cell-34:hover ~ .reflection-content {
    transform: rotateX(5deg) rotateY(-5deg);
  }
  .reflection-container .reflection-grid-cell-34:hover ~ .reflection-content:before {
    transform: translateY(5%);
  }
  .reflection-container .reflection-grid-cell-35 {
    top: 30%;
    left: 40%;
  }
  .reflection-container .reflection-grid-cell-35:hover ~ .reflection-content {
    transform: rotateX(5deg) rotateY(0deg);
  }
  .reflection-container .reflection-grid-cell-35:hover ~ .reflection-content:before {
    transform: translateY(5%);
  }
  .reflection-container .reflection-grid-cell-36 {
    top: 30%;
    left: 50%;
  }
  .reflection-container .reflection-grid-cell-36:hover ~ .reflection-content {
    transform: rotateX(5deg) rotateY(5deg);
  }
  .reflection-container .reflection-grid-cell-36:hover ~ .reflection-content:before {
    transform: translateY(5%);
  }
  .reflection-container .reflection-grid-cell-37 {
    top: 30%;
    left: 60%;
  }
  .reflection-container .reflection-grid-cell-37:hover ~ .reflection-content {
    transform: rotateX(5deg) rotateY(10deg);
  }
  .reflection-container .reflection-grid-cell-37:hover ~ .reflection-content:before {
    transform: translateY(5%);
  }
  .reflection-container .reflection-grid-cell-38 {
    top: 30%;
    left: 70%;
  }
  .reflection-container .reflection-grid-cell-38:hover ~ .reflection-content {
    transform: rotateX(5deg) rotateY(15deg);
  }
  .reflection-container .reflection-grid-cell-38:hover ~ .reflection-content:before {
    transform: translateY(5%);
  }
  .reflection-container .reflection-grid-cell-39 {
    top: 30%;
    left: 80%;
  }
  .reflection-container .reflection-grid-cell-39:hover ~ .reflection-content {
    transform: rotateX(5deg) rotateY(20deg);
  }
  .reflection-container .reflection-grid-cell-39:hover ~ .reflection-content:before {
    transform: translateY(5%);
  }
  .reflection-container .reflection-grid-cell-40 {
    top: 30%;
    left: 90%;
  }
  .reflection-container .reflection-grid-cell-40:hover ~ .reflection-content {
    transform: rotateX(5deg) rotateY(25deg);
  }
  .reflection-container .reflection-grid-cell-40:hover ~ .reflection-content:before {
    transform: translateY(5%);
  }
  .reflection-container .reflection-grid-cell-41 {
    top: 40%;
    left: 0%;
  }
  .reflection-container .reflection-grid-cell-41:hover ~ .reflection-content {
    transform: rotateX(0deg) rotateY(-20deg);
  }
  .reflection-container .reflection-grid-cell-41:hover ~ .reflection-content:before {
    transform: translateY(0%);
  }
  .reflection-container .reflection-grid-cell-42 {
    top: 40%;
    left: 10%;
  }
  .reflection-container .reflection-grid-cell-42:hover ~ .reflection-content {
    transform: rotateX(0deg) rotateY(-15deg);
  }
  .reflection-container .reflection-grid-cell-42:hover ~ .reflection-content:before {
    transform: translateY(0%);
  }
  .reflection-container .reflection-grid-cell-43 {
    top: 40%;
    left: 20%;
  }
  .reflection-container .reflection-grid-cell-43:hover ~ .reflection-content {
    transform: rotateX(0deg) rotateY(-10deg);
  }
  .reflection-container .reflection-grid-cell-43:hover ~ .reflection-content:before {
    transform: translateY(0%);
  }
  .reflection-container .reflection-grid-cell-44 {
    top: 40%;
    left: 30%;
  }
  .reflection-container .reflection-grid-cell-44:hover ~ .reflection-content {
    transform: rotateX(0deg) rotateY(-5deg);
  }
  .reflection-container .reflection-grid-cell-44:hover ~ .reflection-content:before {
    transform: translateY(0%);
  }
  .reflection-container .reflection-grid-cell-45 {
    top: 40%;
    left: 40%;
  }
  .reflection-container .reflection-grid-cell-45:hover ~ .reflection-content {
    transform: rotateX(0deg) rotateY(0deg);
  }
  .reflection-container .reflection-grid-cell-45:hover ~ .reflection-content:before {
    transform: translateY(0%);
  }
  .reflection-container .reflection-grid-cell-46 {
    top: 40%;
    left: 50%;
  }
  .reflection-container .reflection-grid-cell-46:hover ~ .reflection-content {
    transform: rotateX(0deg) rotateY(5deg);
  }
  .reflection-container .reflection-grid-cell-46:hover ~ .reflection-content:before {
    transform: translateY(0%);
  }
  .reflection-container .reflection-grid-cell-47 {
    top: 40%;
    left: 60%;
  }
  .reflection-container .reflection-grid-cell-47:hover ~ .reflection-content {
    transform: rotateX(0deg) rotateY(10deg);
  }
  .reflection-container .reflection-grid-cell-47:hover ~ .reflection-content:before {
    transform: translateY(0%);
  }
  .reflection-container .reflection-grid-cell-48 {
    top: 40%;
    left: 70%;
  }
  .reflection-container .reflection-grid-cell-48:hover ~ .reflection-content {
    transform: rotateX(0deg) rotateY(15deg);
  }
  .reflection-container .reflection-grid-cell-48:hover ~ .reflection-content:before {
    transform: translateY(0%);
  }
  .reflection-container .reflection-grid-cell-49 {
    top: 40%;
    left: 80%;
  }
  .reflection-container .reflection-grid-cell-49:hover ~ .reflection-content {
    transform: rotateX(0deg) rotateY(20deg);
  }
  .reflection-container .reflection-grid-cell-49:hover ~ .reflection-content:before {
    transform: translateY(0%);
  }
  .reflection-container .reflection-grid-cell-50 {
    top: 40%;
    left: 90%;
  }
  .reflection-container .reflection-grid-cell-50:hover ~ .reflection-content {
    transform: rotateX(0deg) rotateY(25deg);
  }
  .reflection-container .reflection-grid-cell-50:hover ~ .reflection-content:before {
    transform: translateY(0%);
  }
  .reflection-container .reflection-grid-cell-51 {
    top: 50%;
    left: 0%;
  }
  .reflection-container .reflection-grid-cell-51:hover ~ .reflection-content {
    transform: rotateX(-5deg) rotateY(-20deg);
  }
  .reflection-container .reflection-grid-cell-51:hover ~ .reflection-content:before {
    transform: translateY(-5%);
  }
  .reflection-container .reflection-grid-cell-52 {
    top: 50%;
    left: 10%;
  }
  .reflection-container .reflection-grid-cell-52:hover ~ .reflection-content {
    transform: rotateX(-5deg) rotateY(-15deg);
  }
  .reflection-container .reflection-grid-cell-52:hover ~ .reflection-content:before {
    transform: translateY(-5%);
  }
  .reflection-container .reflection-grid-cell-53 {
    top: 50%;
    left: 20%;
  }
  .reflection-container .reflection-grid-cell-53:hover ~ .reflection-content {
    transform: rotateX(-5deg) rotateY(-10deg);
  }
  .reflection-container .reflection-grid-cell-53:hover ~ .reflection-content:before {
    transform: translateY(-5%);
  }
  .reflection-container .reflection-grid-cell-54 {
    top: 50%;
    left: 30%;
  }
  .reflection-container .reflection-grid-cell-54:hover ~ .reflection-content {
    transform: rotateX(-5deg) rotateY(-5deg);
  }
  .reflection-container .reflection-grid-cell-54:hover ~ .reflection-content:before {
    transform: translateY(-5%);
  }
  .reflection-container .reflection-grid-cell-55 {
    top: 50%;
    left: 40%;
  }
  .reflection-container .reflection-grid-cell-55:hover ~ .reflection-content {
    transform: rotateX(-5deg) rotateY(0deg);
  }
  .reflection-container .reflection-grid-cell-55:hover ~ .reflection-content:before {
    transform: translateY(-5%);
  }
  .reflection-container .reflection-grid-cell-56 {
    top: 50%;
    left: 50%;
  }
  .reflection-container .reflection-grid-cell-56:hover ~ .reflection-content {
    transform: rotateX(-5deg) rotateY(5deg);
  }
  .reflection-container .reflection-grid-cell-56:hover ~ .reflection-content:before {
    transform: translateY(-5%);
  }
  .reflection-container .reflection-grid-cell-57 {
    top: 50%;
    left: 60%;
  }
  .reflection-container .reflection-grid-cell-57:hover ~ .reflection-content {
    transform: rotateX(-5deg) rotateY(10deg);
  }
  .reflection-container .reflection-grid-cell-57:hover ~ .reflection-content:before {
    transform: translateY(-5%);
  }
  .reflection-container .reflection-grid-cell-58 {
    top: 50%;
    left: 70%;
  }
  .reflection-container .reflection-grid-cell-58:hover ~ .reflection-content {
    transform: rotateX(-5deg) rotateY(15deg);
  }
  .reflection-container .reflection-grid-cell-58:hover ~ .reflection-content:before {
    transform: translateY(-5%);
  }
  .reflection-container .reflection-grid-cell-59 {
    top: 50%;
    left: 80%;
  }
  .reflection-container .reflection-grid-cell-59:hover ~ .reflection-content {
    transform: rotateX(-5deg) rotateY(20deg);
  }
  .reflection-container .reflection-grid-cell-59:hover ~ .reflection-content:before {
    transform: translateY(-5%);
  }
  .reflection-container .reflection-grid-cell-60 {
    top: 50%;
    left: 90%;
  }
  .reflection-container .reflection-grid-cell-60:hover ~ .reflection-content {
    transform: rotateX(-5deg) rotateY(25deg);
  }
  .reflection-container .reflection-grid-cell-60:hover ~ .reflection-content:before {
    transform: translateY(-5%);
  }
  .reflection-container .reflection-grid-cell-61 {
    top: 60%;
    left: 0%;
  }
  .reflection-container .reflection-grid-cell-61:hover ~ .reflection-content {
    transform: rotateX(-10deg) rotateY(-20deg);
  }
  .reflection-container .reflection-grid-cell-61:hover ~ .reflection-content:before {
    transform: translateY(-10%);
  }
  .reflection-container .reflection-grid-cell-62 {
    top: 60%;
    left: 10%;
  }
  .reflection-container .reflection-grid-cell-62:hover ~ .reflection-content {
    transform: rotateX(-10deg) rotateY(-15deg);
  }
  .reflection-container .reflection-grid-cell-62:hover ~ .reflection-content:before {
    transform: translateY(-10%);
  }
  .reflection-container .reflection-grid-cell-63 {
    top: 60%;
    left: 20%;
  }
  .reflection-container .reflection-grid-cell-63:hover ~ .reflection-content {
    transform: rotateX(-10deg) rotateY(-10deg);
  }
  .reflection-container .reflection-grid-cell-63:hover ~ .reflection-content:before {
    transform: translateY(-10%);
  }
  .reflection-container .reflection-grid-cell-64 {
    top: 60%;
    left: 30%;
  }
  .reflection-container .reflection-grid-cell-64:hover ~ .reflection-content {
    transform: rotateX(-10deg) rotateY(-5deg);
  }
  .reflection-container .reflection-grid-cell-64:hover ~ .reflection-content:before {
    transform: translateY(-10%);
  }
  .reflection-container .reflection-grid-cell-65 {
    top: 60%;
    left: 40%;
  }
  .reflection-container .reflection-grid-cell-65:hover ~ .reflection-content {
    transform: rotateX(-10deg) rotateY(0deg);
  }
  .reflection-container .reflection-grid-cell-65:hover ~ .reflection-content:before {
    transform: translateY(-10%);
  }
  .reflection-container .reflection-grid-cell-66 {
    top: 60%;
    left: 50%;
  }
  .reflection-container .reflection-grid-cell-66:hover ~ .reflection-content {
    transform: rotateX(-10deg) rotateY(5deg);
  }
  .reflection-container .reflection-grid-cell-66:hover ~ .reflection-content:before {
    transform: translateY(-10%);
  }
  .reflection-container .reflection-grid-cell-67 {
    top: 60%;
    left: 60%;
  }
  .reflection-container .reflection-grid-cell-67:hover ~ .reflection-content {
    transform: rotateX(-10deg) rotateY(10deg);
  }
  .reflection-container .reflection-grid-cell-67:hover ~ .reflection-content:before {
    transform: translateY(-10%);
  }
  .reflection-container .reflection-grid-cell-68 {
    top: 60%;
    left: 70%;
  }
  .reflection-container .reflection-grid-cell-68:hover ~ .reflection-content {
    transform: rotateX(-10deg) rotateY(15deg);
  }
  .reflection-container .reflection-grid-cell-68:hover ~ .reflection-content:before {
    transform: translateY(-10%);
  }
  .reflection-container .reflection-grid-cell-69 {
    top: 60%;
    left: 80%;
  }
  .reflection-container .reflection-grid-cell-69:hover ~ .reflection-content {
    transform: rotateX(-10deg) rotateY(20deg);
  }
  .reflection-container .reflection-grid-cell-69:hover ~ .reflection-content:before {
    transform: translateY(-10%);
  }
  .reflection-container .reflection-grid-cell-70 {
    top: 60%;
    left: 90%;
  }
  .reflection-container .reflection-grid-cell-70:hover ~ .reflection-content {
    transform: rotateX(-10deg) rotateY(25deg);
  }
  .reflection-container .reflection-grid-cell-70:hover ~ .reflection-content:before {
    transform: translateY(-10%);
  }
  .reflection-container .reflection-grid-cell-71 {
    top: 70%;
    left: 0%;
  }
  .reflection-container .reflection-grid-cell-71:hover ~ .reflection-content {
    transform: rotateX(-15deg) rotateY(-20deg);
  }
  .reflection-container .reflection-grid-cell-71:hover ~ .reflection-content:before {
    transform: translateY(-15%);
  }
  .reflection-container .reflection-grid-cell-72 {
    top: 70%;
    left: 10%;
  }
  .reflection-container .reflection-grid-cell-72:hover ~ .reflection-content {
    transform: rotateX(-15deg) rotateY(-15deg);
  }
  .reflection-container .reflection-grid-cell-72:hover ~ .reflection-content:before {
    transform: translateY(-15%);
  }
  .reflection-container .reflection-grid-cell-73 {
    top: 70%;
    left: 20%;
  }
  .reflection-container .reflection-grid-cell-73:hover ~ .reflection-content {
    transform: rotateX(-15deg) rotateY(-10deg);
  }
  .reflection-container .reflection-grid-cell-73:hover ~ .reflection-content:before {
    transform: translateY(-15%);
  }
  .reflection-container .reflection-grid-cell-74 {
    top: 70%;
    left: 30%;
  }
  .reflection-container .reflection-grid-cell-74:hover ~ .reflection-content {
    transform: rotateX(-15deg) rotateY(-5deg);
  }
  .reflection-container .reflection-grid-cell-74:hover ~ .reflection-content:before {
    transform: translateY(-15%);
  }
  .reflection-container .reflection-grid-cell-75 {
    top: 70%;
    left: 40%;
  }
  .reflection-container .reflection-grid-cell-75:hover ~ .reflection-content {
    transform: rotateX(-15deg) rotateY(0deg);
  }
  .reflection-container .reflection-grid-cell-75:hover ~ .reflection-content:before {
    transform: translateY(-15%);
  }
  .reflection-container .reflection-grid-cell-76 {
    top: 70%;
    left: 50%;
  }
  .reflection-container .reflection-grid-cell-76:hover ~ .reflection-content {
    transform: rotateX(-15deg) rotateY(5deg);
  }
  .reflection-container .reflection-grid-cell-76:hover ~ .reflection-content:before {
    transform: translateY(-15%);
  }
  .reflection-container .reflection-grid-cell-77 {
    top: 70%;
    left: 60%;
  }
  .reflection-container .reflection-grid-cell-77:hover ~ .reflection-content {
    transform: rotateX(-15deg) rotateY(10deg);
  }
  .reflection-container .reflection-grid-cell-77:hover ~ .reflection-content:before {
    transform: translateY(-15%);
  }
  .reflection-container .reflection-grid-cell-78 {
    top: 70%;
    left: 70%;
  }
  .reflection-container .reflection-grid-cell-78:hover ~ .reflection-content {
    transform: rotateX(-15deg) rotateY(15deg);
  }
  .reflection-container .reflection-grid-cell-78:hover ~ .reflection-content:before {
    transform: translateY(-15%);
  }
  .reflection-container .reflection-grid-cell-79 {
    top: 70%;
    left: 80%;
  }
  .reflection-container .reflection-grid-cell-79:hover ~ .reflection-content {
    transform: rotateX(-15deg) rotateY(20deg);
  }
  .reflection-container .reflection-grid-cell-79:hover ~ .reflection-content:before {
    transform: translateY(-15%);
  }
  .reflection-container .reflection-grid-cell-80 {
    top: 70%;
    left: 90%;
  }
  .reflection-container .reflection-grid-cell-80:hover ~ .reflection-content {
    transform: rotateX(-15deg) rotateY(25deg);
  }
  .reflection-container .reflection-grid-cell-80:hover ~ .reflection-content:before {
    transform: translateY(-15%);
  }
  .reflection-container .reflection-grid-cell-81 {
    top: 80%;
    left: 0%;
  }
  .reflection-container .reflection-grid-cell-81:hover ~ .reflection-content {
    transform: rotateX(-20deg) rotateY(-20deg);
  }
  .reflection-container .reflection-grid-cell-81:hover ~ .reflection-content:before {
    transform: translateY(-20%);
  }
  .reflection-container .reflection-grid-cell-82 {
    top: 80%;
    left: 10%;
  }
  .reflection-container .reflection-grid-cell-82:hover ~ .reflection-content {
    transform: rotateX(-20deg) rotateY(-15deg);
  }
  .reflection-container .reflection-grid-cell-82:hover ~ .reflection-content:before {
    transform: translateY(-20%);
  }
  .reflection-container .reflection-grid-cell-83 {
    top: 80%;
    left: 20%;
  }
  .reflection-container .reflection-grid-cell-83:hover ~ .reflection-content {
    transform: rotateX(-20deg) rotateY(-10deg);
  }
  .reflection-container .reflection-grid-cell-83:hover ~ .reflection-content:before {
    transform: translateY(-20%);
  }
  .reflection-container .reflection-grid-cell-84 {
    top: 80%;
    left: 30%;
  }
  .reflection-container .reflection-grid-cell-84:hover ~ .reflection-content {
    transform: rotateX(-20deg) rotateY(-5deg);
  }
  .reflection-container .reflection-grid-cell-84:hover ~ .reflection-content:before {
    transform: translateY(-20%);
  }
  .reflection-container .reflection-grid-cell-85 {
    top: 80%;
    left: 40%;
  }
  .reflection-container .reflection-grid-cell-85:hover ~ .reflection-content {
    transform: rotateX(-20deg) rotateY(0deg);
  }
  .reflection-container .reflection-grid-cell-85:hover ~ .reflection-content:before {
    transform: translateY(-20%);
  }
  .reflection-container .reflection-grid-cell-86 {
    top: 80%;
    left: 50%;
  }
  .reflection-container .reflection-grid-cell-86:hover ~ .reflection-content {
    transform: rotateX(-20deg) rotateY(5deg);
  }
  .reflection-container .reflection-grid-cell-86:hover ~ .reflection-content:before {
    transform: translateY(-20%);
  }
  .reflection-container .reflection-grid-cell-87 {
    top: 80%;
    left: 60%;
  }
  .reflection-container .reflection-grid-cell-87:hover ~ .reflection-content {
    transform: rotateX(-20deg) rotateY(10deg);
  }
  .reflection-container .reflection-grid-cell-87:hover ~ .reflection-content:before {
    transform: translateY(-20%);
  }
  .reflection-container .reflection-grid-cell-88 {
    top: 80%;
    left: 70%;
  }
  .reflection-container .reflection-grid-cell-88:hover ~ .reflection-content {
    transform: rotateX(-20deg) rotateY(15deg);
  }
  .reflection-container .reflection-grid-cell-88:hover ~ .reflection-content:before {
    transform: translateY(-20%);
  }
  .reflection-container .reflection-grid-cell-89 {
    top: 80%;
    left: 80%;
  }
  .reflection-container .reflection-grid-cell-89:hover ~ .reflection-content {
    transform: rotateX(-20deg) rotateY(20deg);
  }
  .reflection-container .reflection-grid-cell-89:hover ~ .reflection-content:before {
    transform: translateY(-20%);
  }
  .reflection-container .reflection-grid-cell-90 {
    top: 80%;
    left: 90%;
  }
  .reflection-container .reflection-grid-cell-90:hover ~ .reflection-content {
    transform: rotateX(-20deg) rotateY(25deg);
  }
  .reflection-container .reflection-grid-cell-90:hover ~ .reflection-content:before {
    transform: translateY(-20%);
  }
  .reflection-container .reflection-grid-cell-91 {
    top: 90%;
    left: 0%;
  }
  .reflection-container .reflection-grid-cell-91:hover ~ .reflection-content {
    transform: rotateX(-25deg) rotateY(-20deg);
  }
  .reflection-container .reflection-grid-cell-91:hover ~ .reflection-content:before {
    transform: translateY(-25%);
  }
  .reflection-container .reflection-grid-cell-92 {
    top: 90%;
    left: 10%;
  }
  .reflection-container .reflection-grid-cell-92:hover ~ .reflection-content {
    transform: rotateX(-25deg) rotateY(-15deg);
  }
  .reflection-container .reflection-grid-cell-92:hover ~ .reflection-content:before {
    transform: translateY(-25%);
  }
  .reflection-container .reflection-grid-cell-93 {
    top: 90%;
    left: 20%;
  }
  .reflection-container .reflection-grid-cell-93:hover ~ .reflection-content {
    transform: rotateX(-25deg) rotateY(-10deg);
  }
  .reflection-container .reflection-grid-cell-93:hover ~ .reflection-content:before {
    transform: translateY(-25%);
  }
  .reflection-container .reflection-grid-cell-94 {
    top: 90%;
    left: 30%;
  }
  .reflection-container .reflection-grid-cell-94:hover ~ .reflection-content {
    transform: rotateX(-25deg) rotateY(-5deg);
  }
  .reflection-container .reflection-grid-cell-94:hover ~ .reflection-content:before {
    transform: translateY(-25%);
  }
  .reflection-container .reflection-grid-cell-95 {
    top: 90%;
    left: 40%;
  }
  .reflection-container .reflection-grid-cell-95:hover ~ .reflection-content {
    transform: rotateX(-25deg) rotateY(0deg);
  }
  .reflection-container .reflection-grid-cell-95:hover ~ .reflection-content:before {
    transform: translateY(-25%);
  }
  .reflection-container .reflection-grid-cell-96 {
    top: 90%;
    left: 50%;
  }
  .reflection-container .reflection-grid-cell-96:hover ~ .reflection-content {
    transform: rotateX(-25deg) rotateY(5deg);
  }
  .reflection-container .reflection-grid-cell-96:hover ~ .reflection-content:before {
    transform: translateY(-25%);
  }
  .reflection-container .reflection-grid-cell-97 {
    top: 90%;
    left: 60%;
  }
  .reflection-container .reflection-grid-cell-97:hover ~ .reflection-content {
    transform: rotateX(-25deg) rotateY(10deg);
  }
  .reflection-container .reflection-grid-cell-97:hover ~ .reflection-content:before {
    transform: translateY(-25%);
  }
  .reflection-container .reflection-grid-cell-98 {
    top: 90%;
    left: 70%;
  }
  .reflection-container .reflection-grid-cell-98:hover ~ .reflection-content {
    transform: rotateX(-25deg) rotateY(15deg);
  }
  .reflection-container .reflection-grid-cell-98:hover ~ .reflection-content:before {
    transform: translateY(-25%);
  }
  .reflection-container .reflection-grid-cell-99 {
    top: 90%;
    left: 80%;
  }
  .reflection-container .reflection-grid-cell-99:hover ~ .reflection-content {
    transform: rotateX(-25deg) rotateY(20deg);
  }
  .reflection-container .reflection-grid-cell-99:hover ~ .reflection-content:before {
    transform: translateY(-25%);
  }
  .reflection-container .reflection-grid-cell-100 {
    top: 90%;
    left: 90%;
  }
  .reflection-container .reflection-grid-cell-100:hover ~ .reflection-content {
    transform: rotateX(-25deg) rotateY(25deg);
  }
  .reflection-container .reflection-grid-cell-100:hover ~ .reflection-content:before {
    transform: translateY(-25%);
  }