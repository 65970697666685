@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,300,700);
@import url('https://fonts.googleapis.com/css2?family=Noto+Serif:wght@400;700&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.logo-image{
  /* width: 46px; */
  height: 46px;
  /* border-radius: 50%; */
  overflow: hidden;
  /* margin-top: -6px; */
}

.invisible-scrollbar{
  margin: 0px !important;
}

@media only screen and (min-width: 768px) {
  .big-screen {
    display:flex;
  }
  .small-screen {
    display:none !important;
  }
}
@media only screen and (max-width: 768px) {
  .big-screen {
    display:none !important;
  }
  .small-screen {
    display:block;
  }
}

/* 
 * Position both images in same place
*/
/* .hover-switch > img {
  position: absolute!important;
  top: calc(50% - 100px)!important;
  left: calc(50% - 100px)!important;
} */
/* 
 * Show the last image by default
*/
.hover-switch > img:last-of-type {
  opacity: 1!important;
  transition: opacity 0.5s ease-in-out!important;
  -moz-transition: opacity 0.5s ease-in-out!important;
  -webkit-transition: opacity 0.5s ease-in-out!important;
}
/* 
 * Hide the last image on hover
*/
.hover-switch:hover > img:last-of-type {
  opacity: 0!important;
}
/* Style the links */
a {
  color: #1570A6;
  transition: color .5s;
  text-decoration: none;
}

a:hover{
  color: #F65314 !important;
}

/* .tickr-title,.tickr-box{
  background: transparent !important;
}
.tickr-title{
  display: none;
}
#tickr-scroll{
  height: 1000px!important;
  background: transparent !important;
} */

.tsparticles-canvas-el{
  position:fixed !important;
  left:-25%;
  top:0;
  width:100%;
  height:100%;
}

*, ::after, ::before {
  box-sizing: border-box !important;
}

.newsticker {
  position: -webkit-sticky;
  position: sticky;
  bottom: 0rem;
}

.styles_ticker-list__1YeNe {
  animation: styles_ticker__8iZE9 35s infinite linear !important;
}

.title-main{
	font-family: Open Sans;
	font-weight: 300;
}


.testermain {
  float: left;
  width: 30%;
}
.tester-sub {
  float: right;
  text-align: left!important;
  width: 60%;
}

/* progress bar styles */
.progress-bar{
  height: 5px;
  background: var(--primary);
  margin-top: 20px;
}

/* image grid styles */
.img-grid{
  margin: 20px auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 40px;
}
.img-wrap{
  overflow: hidden;
  height: 0;
  padding: 50% 0;
  /* padding controls height, will always be perfectly square regardless of width */
  position: relative;
  opacity: 0.8;
}
.img-wrap img{
  /* min-width: 100%;
  min-height: 100%; */
  height: 100%;
  position: absolute;
  top: 50%;
  /* left: -50%; */
  left: 50%;
  transform: translate(-50%,-50%);
}

/* modal styles */
.backdrop{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.5);
}
.backdrop img{
  display: block;
  max-width: 60%;
  max-height: 80%;
  margin: 60px auto;
  box-shadow: 3px 5px 7px rgba(0,0,0,0.5);
  border: 3px solid white;
}

.centered-label {
  display: flex !important;
  align-items: center;
}
.centered-label svg{
  margin-right: 5px;
}

.form-signin {
  width: 100%;
  max-width: 330px;
  padding: 15px;
  margin: auto;
}
.form-signin .checkbox {
  font-weight: 400;
}
.form-signin .form-control {
  position: relative;
  box-sizing: border-box;
  height: auto;
  padding: 10px;
  font-size: 16px;
}
.form-signin .form-control:focus {
  z-index: 2;
}
.form-signin input[type="email"] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.form-signin input[type="password"] {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.carousel-caption{
  text-shadow: 0px 0px 20px black,0px 0px 15px black,0px 0px 10px black,0px 0px 5px black;;
}

.pub-page-main > * {
  text-align: left;
}

.modal-wrapper {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  z-index: -1;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}