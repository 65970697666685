@import url(https://fonts.googleapis.com/css?family=Merriweather);

.hidden {position:absolute;visibility:hidden;opacity:0;}

.modal-dialog{
  margin: 72px auto !important;
}
.MuiTimelineItem-content p{
  word-break: break-all !important;
}
.my-masonry-grid {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;
  margin-left: -15px; /* gutter size offset */
  width: auto;
}
.my-masonry-grid_column {
  padding-left: 15px; /* gutter size */
  background-clip: padding-box;
}

/* Style your items */
.my-masonry-grid_column > div { /* change div to reference your elements you put in <Masonry> */
  /* background: grey; */
  margin-bottom: 15px!important;
}

input[type=radio]{
  display: inline-block !important;
}
.sel{
  color: red!important;
}
.App {
  text-align: center;
}

.active{
  font-weight: bolder;
}

.nav-link{
  margin-left: 15px;
  margin-right: 15px;
  opacity: 0.8;
}

*:focus {
  outline: none;
}

/* stylelint-disable selector-list-comma-newline-after */

.blog-header {
  line-height: 1;
  border-bottom: 1px solid #e5e5e5;
}

.blog-header-logo {
  font-family: "Playfair Display", Georgia, "Times New Roman", serif;
  font-size: 2.25rem;
}

.blog-header-logo:hover {
  text-decoration: none;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "Playfair Display", Georgia, "Times New Roman", serif;
}

.display-4 {
  font-size: 2.5rem;
}
@media (max-width: 768px) {
  .display-4 {
    font-size: 3rem;
  }
}

.nav-scroller {
  position: relative;
  z-index: 2;
  height: 2.75rem;
  overflow-y: hidden;
}

.nav-scroller .nav {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  padding-bottom: 1rem;
  margin-top: -1px;
  overflow-x: auto;
  text-align: center;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
}

.nav-scroller .nav-link {
  padding-top: .75rem;
  padding-bottom: .75rem;
  font-size: .875rem;
}

.card-img-right {
  height: 100%;
  border-radius: 0 3px 3px 0;
}

.flex-auto {
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.h-250 { height: 250px; }
@media (max-width: 768px) {
  .h-md-250 { height: 250px; }
}

/* Pagination */
.blog-pagination {
  margin-bottom: 4rem;
}
.blog-pagination > .btn {
  border-radius: 2rem;
}

/*
 * Blog posts
 */
.blog-post {
  margin-bottom: 4rem;
}
.blog-post-title {
  margin-bottom: .25rem;
  font-size: 2.5rem;
}
.blog-post-meta {
  margin-bottom: 1.25rem;
  color: #999;
}

/*
 * Footer
 */
.blog-footer {
  padding: 0.5rem 0;
  color: #999;
  text-align: center;
  background-color: #f9f9f9;
  border-top: .05rem solid #ddd5;
}
.blog-footer p:last-child {
  margin-bottom: 0;
}
.rishaFeed{
  /* height: 100vh!important;
   */
   text-align: left;
  overflow: hidden;
  padding: 0px 15px;
}
.card-header{
  background-color: transparent!important;
  border: none!important;
}
.preserve
{
    text-decoration: none !important;
    border-bottom: 1px solid #ccc;
    color: inherit;
    cursor: pointer;
}

a:hover{
  text-decoration: none!important;
}
.preserveb
{
    text-decoration: none !important;
    color: inherit;
    cursor: pointer;
}

.unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.clickable{
  cursor: pointer;
}

@media screen and (max-width: 992px)
{
  .lab-member {
    grid-template-columns: 1fr 1fr 1fr 1fr !important;
  }

}
@media screen and (max-width: 769px)
{
  .lab-member {
    grid-template-columns: 1fr 1fr 1fr !important;
  }

}

@media screen and (max-width: 350px)
{
  .lab-member {
    grid-template-columns: 1fr 1fr !important;
  }

}
.lab-member {
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
}

.card{
  border-radius: 4px;
  background: #fff;
  box-shadow: 0 6px 10px rgba(0,0,0,.08), 0 0 6px rgba(0,0,0,.05);
    transition: .3s transform cubic-bezier(.155,1.105,.295,1.12),.3s box-shadow,.3s -webkit-transform cubic-bezier(.155,1.105,.295,1.12);
padding: 14px 80px 18px 36px;
cursor: pointer;
}

.card:hover{
   transform: scale(1.05);
box-shadow: 0 10px 20px rgba(0,0,0,.12), 0 4px 8px rgba(0,0,0,.06);
}

.specialModal{
  background-color: rgba(0,0,0,0.5) !important;
  opacity: 1!important;
  /* -webkit-animation: fadein 0.5s; 
  -moz-animation: fadein 0.5s;
   -ms-animation: fadein 0.5s;
    -o-animation: fadein 0.5s;
       animation: fadein 0.5s; */
}
.animated-wrapper > div:not([style*="opacity: 1"]) > .specialModal{
  pointer-events: none;
}
@keyframes fadein {
from { opacity: 0; }
to   { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
from { opacity: 0; }
to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
from { opacity: 0; }
to   { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadein {
from { opacity: 0; }
to   { opacity: 1; }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
from { opacity: 0; }
to   { opacity: 1; }
}

.img-wrap:hover{
  opacity: 0.9;
}


.cf {
  max-width: 600px;
  text-align: center;
  margin: 20px auto;
}
.cf input, .cf textarea {
  border: 0;
  outline: 0;
  padding: 1em;
  -moz-border-radius: 8px;
  -webkit-border-radius: 8px;
  border-radius: 8px;
  display: block;
  width: 100%;
  margin-top: 1em;
  font-family: 'Merriweather', sans-serif;
  -moz-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  resize: none;
}
.cf input:focus, .cf textarea:focus {
  -moz-box-shadow: 0 0px 2px #e74c3c !important;
  -webkit-box-shadow: 0 0px 2px #e74c3c !important;
  box-shadow: 0 0px 2px #e74c3c !important;
}
/* .cf #input-submit {
  color: white;
  transition: all 0.3s;
  background: #a8a8a8;
  cursor: pointer;
}
.cf #input-submit:hover {
  background: #e74c3c;
  -moz-box-shadow: 0 1px 1px 1px rgba(170, 170, 170, 0.6);
  -webkit-box-shadow: 0 1px 1px 1px rgba(170, 170, 170, 0.6);
  box-shadow: 0 1px 1px 1px rgba(170, 170, 170, 0.6);
} */
.cf textarea {
  height: 12.5em;
}

.half {
  float: left;
  width: 48%;
  margin-bottom: 1em;
}

.right {
  width: 50%;
}

.left {
  margin-right: 2%;
}

@media (max-width: 480px) {
  .half {
    width: 100%;
    float: none;
    margin-bottom: 0;
  }
}
/* Clearfix */
.cf:before,
.cf:after {
  content: " ";
  /* 1 */
  display: table;
  /* 2 */
}

.cf:after {
  clear: both;
}

.cfh {
  font-family: "Merriweather", sans-serif;
  text-align: center;
  color: #a8a8a8;
}