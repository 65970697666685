@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,300,700);
@import url(https://fonts.googleapis.com/css2?family=Noto+Serif:wght@400;700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Merriweather);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.logo-image{
  /* width: 46px; */
  height: 46px;
  /* border-radius: 50%; */
  overflow: hidden;
  /* margin-top: -6px; */
}

.invisible-scrollbar{
  margin: 0px !important;
}

@media only screen and (min-width: 768px) {
  .big-screen {
    display:flex;
  }
  .small-screen {
    display:none !important;
  }
}
@media only screen and (max-width: 768px) {
  .big-screen {
    display:none !important;
  }
  .small-screen {
    display:block;
  }
}

/* 
 * Position both images in same place
*/
/* .hover-switch > img {
  position: absolute!important;
  top: calc(50% - 100px)!important;
  left: calc(50% - 100px)!important;
} */
/* 
 * Show the last image by default
*/
.hover-switch > img:last-of-type {
  opacity: 1!important;
  transition: opacity 0.5s ease-in-out!important;
  -moz-transition: opacity 0.5s ease-in-out!important;
  -webkit-transition: opacity 0.5s ease-in-out!important;
}
/* 
 * Hide the last image on hover
*/
.hover-switch:hover > img:last-of-type {
  opacity: 0!important;
}
/* Style the links */
a {
  color: #1570A6;
  transition: color .5s;
  text-decoration: none;
}

a:hover{
  color: #F65314 !important;
}

/* .tickr-title,.tickr-box{
  background: transparent !important;
}
.tickr-title{
  display: none;
}
#tickr-scroll{
  height: 1000px!important;
  background: transparent !important;
} */

.tsparticles-canvas-el{
  position:fixed !important;
  left:-25%;
  top:0;
  width:100%;
  height:100%;
}

*, ::after, ::before {
  box-sizing: border-box !important;
}

.newsticker {
  position: -webkit-sticky;
  position: sticky;
  bottom: 0rem;
}

.styles_ticker-list__1YeNe {
  -webkit-animation: styles_ticker__8iZE9 35s infinite linear !important;
          animation: styles_ticker__8iZE9 35s infinite linear !important;
}

.title-main{
	font-family: Open Sans;
	font-weight: 300;
}


.testermain {
  float: left;
  width: 30%;
}
.tester-sub {
  float: right;
  text-align: left!important;
  width: 60%;
}

/* progress bar styles */
.progress-bar{
  height: 5px;
  background: var(--primary);
  margin-top: 20px;
}

/* image grid styles */
.img-grid{
  margin: 20px auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 40px;
}
.img-wrap{
  overflow: hidden;
  height: 0;
  padding: 50% 0;
  /* padding controls height, will always be perfectly square regardless of width */
  position: relative;
  opacity: 0.8;
}
.img-wrap img{
  /* min-width: 100%;
  min-height: 100%; */
  height: 100%;
  position: absolute;
  top: 50%;
  /* left: -50%; */
  left: 50%;
  -webkit-transform: translate(-50%,-50%);
          transform: translate(-50%,-50%);
}

/* modal styles */
.backdrop{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.5);
}
.backdrop img{
  display: block;
  max-width: 60%;
  max-height: 80%;
  margin: 60px auto;
  box-shadow: 3px 5px 7px rgba(0,0,0,0.5);
  border: 3px solid white;
}

.centered-label {
  display: flex !important;
  align-items: center;
}
.centered-label svg{
  margin-right: 5px;
}

.form-signin {
  width: 100%;
  max-width: 330px;
  padding: 15px;
  margin: auto;
}
.form-signin .checkbox {
  font-weight: 400;
}
.form-signin .form-control {
  position: relative;
  box-sizing: border-box;
  height: auto;
  padding: 10px;
  font-size: 16px;
}
.form-signin .form-control:focus {
  z-index: 2;
}
.form-signin input[type="email"] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.form-signin input[type="password"] {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.carousel-caption{
  text-shadow: 0px 0px 20px black,0px 0px 15px black,0px 0px 10px black,0px 0px 5px black;;
}

.pub-page-main > * {
  text-align: left;
}

.modal-wrapper {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  z-index: -1;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}
#cloud-intro{
  position: relative;
  height: 100%;
  background: url(/static/media/clouds1000.f07da935.png);
  background: url(/static/media/clouds1000.f07da935.png) 0 200px,
              url(/static/media/clouds1200_2.da2aa276.png) 0 300px,
              url(/static/media/clouds1200_1.7af97df7.png) 100px 250px;
	-webkit-animation: wind 20s linear infinite;
	        animation: wind 20s linear infinite;
}
@-webkit-keyframes wind{
  0% {
    background-position: 0 200px, 0 300px, 100px 250px;
  }
  100% {
    background-position: 1000px 200px, 1200px 300px, 1100px 250px;
  }

}
@keyframes wind{
  0% {
    background-position: 0 200px, 0 300px, 100px 250px;
  }
  100% {
    background-position: 1000px 200px, 1200px 300px, 1100px 250px;
  }

}
.button-join{
  transition: all .3s ease-in-out;
  font-family: sans-serif;
}
.button-join:hover {
  -webkit-transform: scale(1.01) translate3d(0, 0, 0);
          transform: scale(1.01) translate3d(0, 0, 0);
  box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.2), 6px 12px 12px 0 rgba(0, 0, 0, 0.25);
  background-color: #e74c3c;
  font-weight: bold;
  color: white;
}
.button-check{
  transition: all .3s ease-in-out;
  font-family: sans-serif;
  background-color: #69C7FF;
}
.button-check:hover {
  -webkit-transform: scale(1.01) translate3d(0, 0, 0);
          transform: scale(1.01) translate3d(0, 0, 0);
  box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.2), 6px 12px 12px 0 rgba(0, 0, 0, 0.25);
  background-color: green;
  font-weight: bold;
  color: white;
}
.reflection-container {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d;
    -webkit-perspective: 1000px;
            perspective: 1000px;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    border-radius: 5px;
  }
  .reflection-container .reflection-content {
    width: 100%;
    /* background: #000 url(https://unsplash.it/800/800/?random); */
    background-size: cover;
    background-position: center;
    -webkit-transform: rotateX(0) rotateY(0);
            transform: rotateX(0) rotateY(0);
    pointer-events: none;
    transition: 100ms linear transform;
    overflow: hidden;
  }
  .reflection-container .reflection-content:before {
    content: '';
    position: absolute;
    width: 200%;
    height: 200%;
    left: -50%;
    top: -50%;
    background: linear-gradient(rgba(255, 255, 255, 0.1), rgba(0, 0, 0, 0.1));
  }
  .reflection-container .reflection-grid-cell {
    position: absolute;
    z-index: 1;
    width: 10%;
    height: 10%;
  }
  .reflection-container .reflection-grid-cell-1 {
    top: 0%;
    left: 0%;
  }
  .reflection-container .reflection-grid-cell-1:hover ~ .reflection-content {
    -webkit-transform: rotateX(20deg) rotateY(-20deg);
            transform: rotateX(20deg) rotateY(-20deg);
  }
  .reflection-container .reflection-grid-cell-1:hover ~ .reflection-content:before {
    -webkit-transform: translateY(20%);
            transform: translateY(20%);
  }
  .reflection-container .reflection-grid-cell-2 {
    top: 0%;
    left: 10%;
  }
  .reflection-container .reflection-grid-cell-2:hover ~ .reflection-content {
    -webkit-transform: rotateX(20deg) rotateY(-15deg);
            transform: rotateX(20deg) rotateY(-15deg);
  }
  .reflection-container .reflection-grid-cell-2:hover ~ .reflection-content:before {
    -webkit-transform: translateY(20%);
            transform: translateY(20%);
  }
  .reflection-container .reflection-grid-cell-3 {
    top: 0%;
    left: 20%;
  }
  .reflection-container .reflection-grid-cell-3:hover ~ .reflection-content {
    -webkit-transform: rotateX(20deg) rotateY(-10deg);
            transform: rotateX(20deg) rotateY(-10deg);
  }
  .reflection-container .reflection-grid-cell-3:hover ~ .reflection-content:before {
    -webkit-transform: translateY(20%);
            transform: translateY(20%);
  }
  .reflection-container .reflection-grid-cell-4 {
    top: 0%;
    left: 30%;
  }
  .reflection-container .reflection-grid-cell-4:hover ~ .reflection-content {
    -webkit-transform: rotateX(20deg) rotateY(-5deg);
            transform: rotateX(20deg) rotateY(-5deg);
  }
  .reflection-container .reflection-grid-cell-4:hover ~ .reflection-content:before {
    -webkit-transform: translateY(20%);
            transform: translateY(20%);
  }
  .reflection-container .reflection-grid-cell-5 {
    top: 0%;
    left: 40%;
  }
  .reflection-container .reflection-grid-cell-5:hover ~ .reflection-content {
    -webkit-transform: rotateX(20deg) rotateY(0deg);
            transform: rotateX(20deg) rotateY(0deg);
  }
  .reflection-container .reflection-grid-cell-5:hover ~ .reflection-content:before {
    -webkit-transform: translateY(20%);
            transform: translateY(20%);
  }
  .reflection-container .reflection-grid-cell-6 {
    top: 0%;
    left: 50%;
  }
  .reflection-container .reflection-grid-cell-6:hover ~ .reflection-content {
    -webkit-transform: rotateX(20deg) rotateY(5deg);
            transform: rotateX(20deg) rotateY(5deg);
  }
  .reflection-container .reflection-grid-cell-6:hover ~ .reflection-content:before {
    -webkit-transform: translateY(20%);
            transform: translateY(20%);
  }
  .reflection-container .reflection-grid-cell-7 {
    top: 0%;
    left: 60%;
  }
  .reflection-container .reflection-grid-cell-7:hover ~ .reflection-content {
    -webkit-transform: rotateX(20deg) rotateY(10deg);
            transform: rotateX(20deg) rotateY(10deg);
  }
  .reflection-container .reflection-grid-cell-7:hover ~ .reflection-content:before {
    -webkit-transform: translateY(20%);
            transform: translateY(20%);
  }
  .reflection-container .reflection-grid-cell-8 {
    top: 0%;
    left: 70%;
  }
  .reflection-container .reflection-grid-cell-8:hover ~ .reflection-content {
    -webkit-transform: rotateX(20deg) rotateY(15deg);
            transform: rotateX(20deg) rotateY(15deg);
  }
  .reflection-container .reflection-grid-cell-8:hover ~ .reflection-content:before {
    -webkit-transform: translateY(20%);
            transform: translateY(20%);
  }
  .reflection-container .reflection-grid-cell-9 {
    top: 0%;
    left: 80%;
  }
  .reflection-container .reflection-grid-cell-9:hover ~ .reflection-content {
    -webkit-transform: rotateX(20deg) rotateY(20deg);
            transform: rotateX(20deg) rotateY(20deg);
  }
  .reflection-container .reflection-grid-cell-9:hover ~ .reflection-content:before {
    -webkit-transform: translateY(20%);
            transform: translateY(20%);
  }
  .reflection-container .reflection-grid-cell-10 {
    top: 0%;
    left: 90%;
  }
  .reflection-container .reflection-grid-cell-10:hover ~ .reflection-content {
    -webkit-transform: rotateX(20deg) rotateY(25deg);
            transform: rotateX(20deg) rotateY(25deg);
  }
  .reflection-container .reflection-grid-cell-10:hover ~ .reflection-content:before {
    -webkit-transform: translateY(20%);
            transform: translateY(20%);
  }
  .reflection-container .reflection-grid-cell-11 {
    top: 10%;
    left: 0%;
  }
  .reflection-container .reflection-grid-cell-11:hover ~ .reflection-content {
    -webkit-transform: rotateX(15deg) rotateY(-20deg);
            transform: rotateX(15deg) rotateY(-20deg);
  }
  .reflection-container .reflection-grid-cell-11:hover ~ .reflection-content:before {
    -webkit-transform: translateY(15%);
            transform: translateY(15%);
  }
  .reflection-container .reflection-grid-cell-12 {
    top: 10%;
    left: 10%;
  }
  .reflection-container .reflection-grid-cell-12:hover ~ .reflection-content {
    -webkit-transform: rotateX(15deg) rotateY(-15deg);
            transform: rotateX(15deg) rotateY(-15deg);
  }
  .reflection-container .reflection-grid-cell-12:hover ~ .reflection-content:before {
    -webkit-transform: translateY(15%);
            transform: translateY(15%);
  }
  .reflection-container .reflection-grid-cell-13 {
    top: 10%;
    left: 20%;
  }
  .reflection-container .reflection-grid-cell-13:hover ~ .reflection-content {
    -webkit-transform: rotateX(15deg) rotateY(-10deg);
            transform: rotateX(15deg) rotateY(-10deg);
  }
  .reflection-container .reflection-grid-cell-13:hover ~ .reflection-content:before {
    -webkit-transform: translateY(15%);
            transform: translateY(15%);
  }
  .reflection-container .reflection-grid-cell-14 {
    top: 10%;
    left: 30%;
  }
  .reflection-container .reflection-grid-cell-14:hover ~ .reflection-content {
    -webkit-transform: rotateX(15deg) rotateY(-5deg);
            transform: rotateX(15deg) rotateY(-5deg);
  }
  .reflection-container .reflection-grid-cell-14:hover ~ .reflection-content:before {
    -webkit-transform: translateY(15%);
            transform: translateY(15%);
  }
  .reflection-container .reflection-grid-cell-15 {
    top: 10%;
    left: 40%;
  }
  .reflection-container .reflection-grid-cell-15:hover ~ .reflection-content {
    -webkit-transform: rotateX(15deg) rotateY(0deg);
            transform: rotateX(15deg) rotateY(0deg);
  }
  .reflection-container .reflection-grid-cell-15:hover ~ .reflection-content:before {
    -webkit-transform: translateY(15%);
            transform: translateY(15%);
  }
  .reflection-container .reflection-grid-cell-16 {
    top: 10%;
    left: 50%;
  }
  .reflection-container .reflection-grid-cell-16:hover ~ .reflection-content {
    -webkit-transform: rotateX(15deg) rotateY(5deg);
            transform: rotateX(15deg) rotateY(5deg);
  }
  .reflection-container .reflection-grid-cell-16:hover ~ .reflection-content:before {
    -webkit-transform: translateY(15%);
            transform: translateY(15%);
  }
  .reflection-container .reflection-grid-cell-17 {
    top: 10%;
    left: 60%;
  }
  .reflection-container .reflection-grid-cell-17:hover ~ .reflection-content {
    -webkit-transform: rotateX(15deg) rotateY(10deg);
            transform: rotateX(15deg) rotateY(10deg);
  }
  .reflection-container .reflection-grid-cell-17:hover ~ .reflection-content:before {
    -webkit-transform: translateY(15%);
            transform: translateY(15%);
  }
  .reflection-container .reflection-grid-cell-18 {
    top: 10%;
    left: 70%;
  }
  .reflection-container .reflection-grid-cell-18:hover ~ .reflection-content {
    -webkit-transform: rotateX(15deg) rotateY(15deg);
            transform: rotateX(15deg) rotateY(15deg);
  }
  .reflection-container .reflection-grid-cell-18:hover ~ .reflection-content:before {
    -webkit-transform: translateY(15%);
            transform: translateY(15%);
  }
  .reflection-container .reflection-grid-cell-19 {
    top: 10%;
    left: 80%;
  }
  .reflection-container .reflection-grid-cell-19:hover ~ .reflection-content {
    -webkit-transform: rotateX(15deg) rotateY(20deg);
            transform: rotateX(15deg) rotateY(20deg);
  }
  .reflection-container .reflection-grid-cell-19:hover ~ .reflection-content:before {
    -webkit-transform: translateY(15%);
            transform: translateY(15%);
  }
  .reflection-container .reflection-grid-cell-20 {
    top: 10%;
    left: 90%;
  }
  .reflection-container .reflection-grid-cell-20:hover ~ .reflection-content {
    -webkit-transform: rotateX(15deg) rotateY(25deg);
            transform: rotateX(15deg) rotateY(25deg);
  }
  .reflection-container .reflection-grid-cell-20:hover ~ .reflection-content:before {
    -webkit-transform: translateY(15%);
            transform: translateY(15%);
  }
  .reflection-container .reflection-grid-cell-21 {
    top: 20%;
    left: 0%;
  }
  .reflection-container .reflection-grid-cell-21:hover ~ .reflection-content {
    -webkit-transform: rotateX(10deg) rotateY(-20deg);
            transform: rotateX(10deg) rotateY(-20deg);
  }
  .reflection-container .reflection-grid-cell-21:hover ~ .reflection-content:before {
    -webkit-transform: translateY(10%);
            transform: translateY(10%);
  }
  .reflection-container .reflection-grid-cell-22 {
    top: 20%;
    left: 10%;
  }
  .reflection-container .reflection-grid-cell-22:hover ~ .reflection-content {
    -webkit-transform: rotateX(10deg) rotateY(-15deg);
            transform: rotateX(10deg) rotateY(-15deg);
  }
  .reflection-container .reflection-grid-cell-22:hover ~ .reflection-content:before {
    -webkit-transform: translateY(10%);
            transform: translateY(10%);
  }
  .reflection-container .reflection-grid-cell-23 {
    top: 20%;
    left: 20%;
  }
  .reflection-container .reflection-grid-cell-23:hover ~ .reflection-content {
    -webkit-transform: rotateX(10deg) rotateY(-10deg);
            transform: rotateX(10deg) rotateY(-10deg);
  }
  .reflection-container .reflection-grid-cell-23:hover ~ .reflection-content:before {
    -webkit-transform: translateY(10%);
            transform: translateY(10%);
  }
  .reflection-container .reflection-grid-cell-24 {
    top: 20%;
    left: 30%;
  }
  .reflection-container .reflection-grid-cell-24:hover ~ .reflection-content {
    -webkit-transform: rotateX(10deg) rotateY(-5deg);
            transform: rotateX(10deg) rotateY(-5deg);
  }
  .reflection-container .reflection-grid-cell-24:hover ~ .reflection-content:before {
    -webkit-transform: translateY(10%);
            transform: translateY(10%);
  }
  .reflection-container .reflection-grid-cell-25 {
    top: 20%;
    left: 40%;
  }
  .reflection-container .reflection-grid-cell-25:hover ~ .reflection-content {
    -webkit-transform: rotateX(10deg) rotateY(0deg);
            transform: rotateX(10deg) rotateY(0deg);
  }
  .reflection-container .reflection-grid-cell-25:hover ~ .reflection-content:before {
    -webkit-transform: translateY(10%);
            transform: translateY(10%);
  }
  .reflection-container .reflection-grid-cell-26 {
    top: 20%;
    left: 50%;
  }
  .reflection-container .reflection-grid-cell-26:hover ~ .reflection-content {
    -webkit-transform: rotateX(10deg) rotateY(5deg);
            transform: rotateX(10deg) rotateY(5deg);
  }
  .reflection-container .reflection-grid-cell-26:hover ~ .reflection-content:before {
    -webkit-transform: translateY(10%);
            transform: translateY(10%);
  }
  .reflection-container .reflection-grid-cell-27 {
    top: 20%;
    left: 60%;
  }
  .reflection-container .reflection-grid-cell-27:hover ~ .reflection-content {
    -webkit-transform: rotateX(10deg) rotateY(10deg);
            transform: rotateX(10deg) rotateY(10deg);
  }
  .reflection-container .reflection-grid-cell-27:hover ~ .reflection-content:before {
    -webkit-transform: translateY(10%);
            transform: translateY(10%);
  }
  .reflection-container .reflection-grid-cell-28 {
    top: 20%;
    left: 70%;
  }
  .reflection-container .reflection-grid-cell-28:hover ~ .reflection-content {
    -webkit-transform: rotateX(10deg) rotateY(15deg);
            transform: rotateX(10deg) rotateY(15deg);
  }
  .reflection-container .reflection-grid-cell-28:hover ~ .reflection-content:before {
    -webkit-transform: translateY(10%);
            transform: translateY(10%);
  }
  .reflection-container .reflection-grid-cell-29 {
    top: 20%;
    left: 80%;
  }
  .reflection-container .reflection-grid-cell-29:hover ~ .reflection-content {
    -webkit-transform: rotateX(10deg) rotateY(20deg);
            transform: rotateX(10deg) rotateY(20deg);
  }
  .reflection-container .reflection-grid-cell-29:hover ~ .reflection-content:before {
    -webkit-transform: translateY(10%);
            transform: translateY(10%);
  }
  .reflection-container .reflection-grid-cell-30 {
    top: 20%;
    left: 90%;
  }
  .reflection-container .reflection-grid-cell-30:hover ~ .reflection-content {
    -webkit-transform: rotateX(10deg) rotateY(25deg);
            transform: rotateX(10deg) rotateY(25deg);
  }
  .reflection-container .reflection-grid-cell-30:hover ~ .reflection-content:before {
    -webkit-transform: translateY(10%);
            transform: translateY(10%);
  }
  .reflection-container .reflection-grid-cell-31 {
    top: 30%;
    left: 0%;
  }
  .reflection-container .reflection-grid-cell-31:hover ~ .reflection-content {
    -webkit-transform: rotateX(5deg) rotateY(-20deg);
            transform: rotateX(5deg) rotateY(-20deg);
  }
  .reflection-container .reflection-grid-cell-31:hover ~ .reflection-content:before {
    -webkit-transform: translateY(5%);
            transform: translateY(5%);
  }
  .reflection-container .reflection-grid-cell-32 {
    top: 30%;
    left: 10%;
  }
  .reflection-container .reflection-grid-cell-32:hover ~ .reflection-content {
    -webkit-transform: rotateX(5deg) rotateY(-15deg);
            transform: rotateX(5deg) rotateY(-15deg);
  }
  .reflection-container .reflection-grid-cell-32:hover ~ .reflection-content:before {
    -webkit-transform: translateY(5%);
            transform: translateY(5%);
  }
  .reflection-container .reflection-grid-cell-33 {
    top: 30%;
    left: 20%;
  }
  .reflection-container .reflection-grid-cell-33:hover ~ .reflection-content {
    -webkit-transform: rotateX(5deg) rotateY(-10deg);
            transform: rotateX(5deg) rotateY(-10deg);
  }
  .reflection-container .reflection-grid-cell-33:hover ~ .reflection-content:before {
    -webkit-transform: translateY(5%);
            transform: translateY(5%);
  }
  .reflection-container .reflection-grid-cell-34 {
    top: 30%;
    left: 30%;
  }
  .reflection-container .reflection-grid-cell-34:hover ~ .reflection-content {
    -webkit-transform: rotateX(5deg) rotateY(-5deg);
            transform: rotateX(5deg) rotateY(-5deg);
  }
  .reflection-container .reflection-grid-cell-34:hover ~ .reflection-content:before {
    -webkit-transform: translateY(5%);
            transform: translateY(5%);
  }
  .reflection-container .reflection-grid-cell-35 {
    top: 30%;
    left: 40%;
  }
  .reflection-container .reflection-grid-cell-35:hover ~ .reflection-content {
    -webkit-transform: rotateX(5deg) rotateY(0deg);
            transform: rotateX(5deg) rotateY(0deg);
  }
  .reflection-container .reflection-grid-cell-35:hover ~ .reflection-content:before {
    -webkit-transform: translateY(5%);
            transform: translateY(5%);
  }
  .reflection-container .reflection-grid-cell-36 {
    top: 30%;
    left: 50%;
  }
  .reflection-container .reflection-grid-cell-36:hover ~ .reflection-content {
    -webkit-transform: rotateX(5deg) rotateY(5deg);
            transform: rotateX(5deg) rotateY(5deg);
  }
  .reflection-container .reflection-grid-cell-36:hover ~ .reflection-content:before {
    -webkit-transform: translateY(5%);
            transform: translateY(5%);
  }
  .reflection-container .reflection-grid-cell-37 {
    top: 30%;
    left: 60%;
  }
  .reflection-container .reflection-grid-cell-37:hover ~ .reflection-content {
    -webkit-transform: rotateX(5deg) rotateY(10deg);
            transform: rotateX(5deg) rotateY(10deg);
  }
  .reflection-container .reflection-grid-cell-37:hover ~ .reflection-content:before {
    -webkit-transform: translateY(5%);
            transform: translateY(5%);
  }
  .reflection-container .reflection-grid-cell-38 {
    top: 30%;
    left: 70%;
  }
  .reflection-container .reflection-grid-cell-38:hover ~ .reflection-content {
    -webkit-transform: rotateX(5deg) rotateY(15deg);
            transform: rotateX(5deg) rotateY(15deg);
  }
  .reflection-container .reflection-grid-cell-38:hover ~ .reflection-content:before {
    -webkit-transform: translateY(5%);
            transform: translateY(5%);
  }
  .reflection-container .reflection-grid-cell-39 {
    top: 30%;
    left: 80%;
  }
  .reflection-container .reflection-grid-cell-39:hover ~ .reflection-content {
    -webkit-transform: rotateX(5deg) rotateY(20deg);
            transform: rotateX(5deg) rotateY(20deg);
  }
  .reflection-container .reflection-grid-cell-39:hover ~ .reflection-content:before {
    -webkit-transform: translateY(5%);
            transform: translateY(5%);
  }
  .reflection-container .reflection-grid-cell-40 {
    top: 30%;
    left: 90%;
  }
  .reflection-container .reflection-grid-cell-40:hover ~ .reflection-content {
    -webkit-transform: rotateX(5deg) rotateY(25deg);
            transform: rotateX(5deg) rotateY(25deg);
  }
  .reflection-container .reflection-grid-cell-40:hover ~ .reflection-content:before {
    -webkit-transform: translateY(5%);
            transform: translateY(5%);
  }
  .reflection-container .reflection-grid-cell-41 {
    top: 40%;
    left: 0%;
  }
  .reflection-container .reflection-grid-cell-41:hover ~ .reflection-content {
    -webkit-transform: rotateX(0deg) rotateY(-20deg);
            transform: rotateX(0deg) rotateY(-20deg);
  }
  .reflection-container .reflection-grid-cell-41:hover ~ .reflection-content:before {
    -webkit-transform: translateY(0%);
            transform: translateY(0%);
  }
  .reflection-container .reflection-grid-cell-42 {
    top: 40%;
    left: 10%;
  }
  .reflection-container .reflection-grid-cell-42:hover ~ .reflection-content {
    -webkit-transform: rotateX(0deg) rotateY(-15deg);
            transform: rotateX(0deg) rotateY(-15deg);
  }
  .reflection-container .reflection-grid-cell-42:hover ~ .reflection-content:before {
    -webkit-transform: translateY(0%);
            transform: translateY(0%);
  }
  .reflection-container .reflection-grid-cell-43 {
    top: 40%;
    left: 20%;
  }
  .reflection-container .reflection-grid-cell-43:hover ~ .reflection-content {
    -webkit-transform: rotateX(0deg) rotateY(-10deg);
            transform: rotateX(0deg) rotateY(-10deg);
  }
  .reflection-container .reflection-grid-cell-43:hover ~ .reflection-content:before {
    -webkit-transform: translateY(0%);
            transform: translateY(0%);
  }
  .reflection-container .reflection-grid-cell-44 {
    top: 40%;
    left: 30%;
  }
  .reflection-container .reflection-grid-cell-44:hover ~ .reflection-content {
    -webkit-transform: rotateX(0deg) rotateY(-5deg);
            transform: rotateX(0deg) rotateY(-5deg);
  }
  .reflection-container .reflection-grid-cell-44:hover ~ .reflection-content:before {
    -webkit-transform: translateY(0%);
            transform: translateY(0%);
  }
  .reflection-container .reflection-grid-cell-45 {
    top: 40%;
    left: 40%;
  }
  .reflection-container .reflection-grid-cell-45:hover ~ .reflection-content {
    -webkit-transform: rotateX(0deg) rotateY(0deg);
            transform: rotateX(0deg) rotateY(0deg);
  }
  .reflection-container .reflection-grid-cell-45:hover ~ .reflection-content:before {
    -webkit-transform: translateY(0%);
            transform: translateY(0%);
  }
  .reflection-container .reflection-grid-cell-46 {
    top: 40%;
    left: 50%;
  }
  .reflection-container .reflection-grid-cell-46:hover ~ .reflection-content {
    -webkit-transform: rotateX(0deg) rotateY(5deg);
            transform: rotateX(0deg) rotateY(5deg);
  }
  .reflection-container .reflection-grid-cell-46:hover ~ .reflection-content:before {
    -webkit-transform: translateY(0%);
            transform: translateY(0%);
  }
  .reflection-container .reflection-grid-cell-47 {
    top: 40%;
    left: 60%;
  }
  .reflection-container .reflection-grid-cell-47:hover ~ .reflection-content {
    -webkit-transform: rotateX(0deg) rotateY(10deg);
            transform: rotateX(0deg) rotateY(10deg);
  }
  .reflection-container .reflection-grid-cell-47:hover ~ .reflection-content:before {
    -webkit-transform: translateY(0%);
            transform: translateY(0%);
  }
  .reflection-container .reflection-grid-cell-48 {
    top: 40%;
    left: 70%;
  }
  .reflection-container .reflection-grid-cell-48:hover ~ .reflection-content {
    -webkit-transform: rotateX(0deg) rotateY(15deg);
            transform: rotateX(0deg) rotateY(15deg);
  }
  .reflection-container .reflection-grid-cell-48:hover ~ .reflection-content:before {
    -webkit-transform: translateY(0%);
            transform: translateY(0%);
  }
  .reflection-container .reflection-grid-cell-49 {
    top: 40%;
    left: 80%;
  }
  .reflection-container .reflection-grid-cell-49:hover ~ .reflection-content {
    -webkit-transform: rotateX(0deg) rotateY(20deg);
            transform: rotateX(0deg) rotateY(20deg);
  }
  .reflection-container .reflection-grid-cell-49:hover ~ .reflection-content:before {
    -webkit-transform: translateY(0%);
            transform: translateY(0%);
  }
  .reflection-container .reflection-grid-cell-50 {
    top: 40%;
    left: 90%;
  }
  .reflection-container .reflection-grid-cell-50:hover ~ .reflection-content {
    -webkit-transform: rotateX(0deg) rotateY(25deg);
            transform: rotateX(0deg) rotateY(25deg);
  }
  .reflection-container .reflection-grid-cell-50:hover ~ .reflection-content:before {
    -webkit-transform: translateY(0%);
            transform: translateY(0%);
  }
  .reflection-container .reflection-grid-cell-51 {
    top: 50%;
    left: 0%;
  }
  .reflection-container .reflection-grid-cell-51:hover ~ .reflection-content {
    -webkit-transform: rotateX(-5deg) rotateY(-20deg);
            transform: rotateX(-5deg) rotateY(-20deg);
  }
  .reflection-container .reflection-grid-cell-51:hover ~ .reflection-content:before {
    -webkit-transform: translateY(-5%);
            transform: translateY(-5%);
  }
  .reflection-container .reflection-grid-cell-52 {
    top: 50%;
    left: 10%;
  }
  .reflection-container .reflection-grid-cell-52:hover ~ .reflection-content {
    -webkit-transform: rotateX(-5deg) rotateY(-15deg);
            transform: rotateX(-5deg) rotateY(-15deg);
  }
  .reflection-container .reflection-grid-cell-52:hover ~ .reflection-content:before {
    -webkit-transform: translateY(-5%);
            transform: translateY(-5%);
  }
  .reflection-container .reflection-grid-cell-53 {
    top: 50%;
    left: 20%;
  }
  .reflection-container .reflection-grid-cell-53:hover ~ .reflection-content {
    -webkit-transform: rotateX(-5deg) rotateY(-10deg);
            transform: rotateX(-5deg) rotateY(-10deg);
  }
  .reflection-container .reflection-grid-cell-53:hover ~ .reflection-content:before {
    -webkit-transform: translateY(-5%);
            transform: translateY(-5%);
  }
  .reflection-container .reflection-grid-cell-54 {
    top: 50%;
    left: 30%;
  }
  .reflection-container .reflection-grid-cell-54:hover ~ .reflection-content {
    -webkit-transform: rotateX(-5deg) rotateY(-5deg);
            transform: rotateX(-5deg) rotateY(-5deg);
  }
  .reflection-container .reflection-grid-cell-54:hover ~ .reflection-content:before {
    -webkit-transform: translateY(-5%);
            transform: translateY(-5%);
  }
  .reflection-container .reflection-grid-cell-55 {
    top: 50%;
    left: 40%;
  }
  .reflection-container .reflection-grid-cell-55:hover ~ .reflection-content {
    -webkit-transform: rotateX(-5deg) rotateY(0deg);
            transform: rotateX(-5deg) rotateY(0deg);
  }
  .reflection-container .reflection-grid-cell-55:hover ~ .reflection-content:before {
    -webkit-transform: translateY(-5%);
            transform: translateY(-5%);
  }
  .reflection-container .reflection-grid-cell-56 {
    top: 50%;
    left: 50%;
  }
  .reflection-container .reflection-grid-cell-56:hover ~ .reflection-content {
    -webkit-transform: rotateX(-5deg) rotateY(5deg);
            transform: rotateX(-5deg) rotateY(5deg);
  }
  .reflection-container .reflection-grid-cell-56:hover ~ .reflection-content:before {
    -webkit-transform: translateY(-5%);
            transform: translateY(-5%);
  }
  .reflection-container .reflection-grid-cell-57 {
    top: 50%;
    left: 60%;
  }
  .reflection-container .reflection-grid-cell-57:hover ~ .reflection-content {
    -webkit-transform: rotateX(-5deg) rotateY(10deg);
            transform: rotateX(-5deg) rotateY(10deg);
  }
  .reflection-container .reflection-grid-cell-57:hover ~ .reflection-content:before {
    -webkit-transform: translateY(-5%);
            transform: translateY(-5%);
  }
  .reflection-container .reflection-grid-cell-58 {
    top: 50%;
    left: 70%;
  }
  .reflection-container .reflection-grid-cell-58:hover ~ .reflection-content {
    -webkit-transform: rotateX(-5deg) rotateY(15deg);
            transform: rotateX(-5deg) rotateY(15deg);
  }
  .reflection-container .reflection-grid-cell-58:hover ~ .reflection-content:before {
    -webkit-transform: translateY(-5%);
            transform: translateY(-5%);
  }
  .reflection-container .reflection-grid-cell-59 {
    top: 50%;
    left: 80%;
  }
  .reflection-container .reflection-grid-cell-59:hover ~ .reflection-content {
    -webkit-transform: rotateX(-5deg) rotateY(20deg);
            transform: rotateX(-5deg) rotateY(20deg);
  }
  .reflection-container .reflection-grid-cell-59:hover ~ .reflection-content:before {
    -webkit-transform: translateY(-5%);
            transform: translateY(-5%);
  }
  .reflection-container .reflection-grid-cell-60 {
    top: 50%;
    left: 90%;
  }
  .reflection-container .reflection-grid-cell-60:hover ~ .reflection-content {
    -webkit-transform: rotateX(-5deg) rotateY(25deg);
            transform: rotateX(-5deg) rotateY(25deg);
  }
  .reflection-container .reflection-grid-cell-60:hover ~ .reflection-content:before {
    -webkit-transform: translateY(-5%);
            transform: translateY(-5%);
  }
  .reflection-container .reflection-grid-cell-61 {
    top: 60%;
    left: 0%;
  }
  .reflection-container .reflection-grid-cell-61:hover ~ .reflection-content {
    -webkit-transform: rotateX(-10deg) rotateY(-20deg);
            transform: rotateX(-10deg) rotateY(-20deg);
  }
  .reflection-container .reflection-grid-cell-61:hover ~ .reflection-content:before {
    -webkit-transform: translateY(-10%);
            transform: translateY(-10%);
  }
  .reflection-container .reflection-grid-cell-62 {
    top: 60%;
    left: 10%;
  }
  .reflection-container .reflection-grid-cell-62:hover ~ .reflection-content {
    -webkit-transform: rotateX(-10deg) rotateY(-15deg);
            transform: rotateX(-10deg) rotateY(-15deg);
  }
  .reflection-container .reflection-grid-cell-62:hover ~ .reflection-content:before {
    -webkit-transform: translateY(-10%);
            transform: translateY(-10%);
  }
  .reflection-container .reflection-grid-cell-63 {
    top: 60%;
    left: 20%;
  }
  .reflection-container .reflection-grid-cell-63:hover ~ .reflection-content {
    -webkit-transform: rotateX(-10deg) rotateY(-10deg);
            transform: rotateX(-10deg) rotateY(-10deg);
  }
  .reflection-container .reflection-grid-cell-63:hover ~ .reflection-content:before {
    -webkit-transform: translateY(-10%);
            transform: translateY(-10%);
  }
  .reflection-container .reflection-grid-cell-64 {
    top: 60%;
    left: 30%;
  }
  .reflection-container .reflection-grid-cell-64:hover ~ .reflection-content {
    -webkit-transform: rotateX(-10deg) rotateY(-5deg);
            transform: rotateX(-10deg) rotateY(-5deg);
  }
  .reflection-container .reflection-grid-cell-64:hover ~ .reflection-content:before {
    -webkit-transform: translateY(-10%);
            transform: translateY(-10%);
  }
  .reflection-container .reflection-grid-cell-65 {
    top: 60%;
    left: 40%;
  }
  .reflection-container .reflection-grid-cell-65:hover ~ .reflection-content {
    -webkit-transform: rotateX(-10deg) rotateY(0deg);
            transform: rotateX(-10deg) rotateY(0deg);
  }
  .reflection-container .reflection-grid-cell-65:hover ~ .reflection-content:before {
    -webkit-transform: translateY(-10%);
            transform: translateY(-10%);
  }
  .reflection-container .reflection-grid-cell-66 {
    top: 60%;
    left: 50%;
  }
  .reflection-container .reflection-grid-cell-66:hover ~ .reflection-content {
    -webkit-transform: rotateX(-10deg) rotateY(5deg);
            transform: rotateX(-10deg) rotateY(5deg);
  }
  .reflection-container .reflection-grid-cell-66:hover ~ .reflection-content:before {
    -webkit-transform: translateY(-10%);
            transform: translateY(-10%);
  }
  .reflection-container .reflection-grid-cell-67 {
    top: 60%;
    left: 60%;
  }
  .reflection-container .reflection-grid-cell-67:hover ~ .reflection-content {
    -webkit-transform: rotateX(-10deg) rotateY(10deg);
            transform: rotateX(-10deg) rotateY(10deg);
  }
  .reflection-container .reflection-grid-cell-67:hover ~ .reflection-content:before {
    -webkit-transform: translateY(-10%);
            transform: translateY(-10%);
  }
  .reflection-container .reflection-grid-cell-68 {
    top: 60%;
    left: 70%;
  }
  .reflection-container .reflection-grid-cell-68:hover ~ .reflection-content {
    -webkit-transform: rotateX(-10deg) rotateY(15deg);
            transform: rotateX(-10deg) rotateY(15deg);
  }
  .reflection-container .reflection-grid-cell-68:hover ~ .reflection-content:before {
    -webkit-transform: translateY(-10%);
            transform: translateY(-10%);
  }
  .reflection-container .reflection-grid-cell-69 {
    top: 60%;
    left: 80%;
  }
  .reflection-container .reflection-grid-cell-69:hover ~ .reflection-content {
    -webkit-transform: rotateX(-10deg) rotateY(20deg);
            transform: rotateX(-10deg) rotateY(20deg);
  }
  .reflection-container .reflection-grid-cell-69:hover ~ .reflection-content:before {
    -webkit-transform: translateY(-10%);
            transform: translateY(-10%);
  }
  .reflection-container .reflection-grid-cell-70 {
    top: 60%;
    left: 90%;
  }
  .reflection-container .reflection-grid-cell-70:hover ~ .reflection-content {
    -webkit-transform: rotateX(-10deg) rotateY(25deg);
            transform: rotateX(-10deg) rotateY(25deg);
  }
  .reflection-container .reflection-grid-cell-70:hover ~ .reflection-content:before {
    -webkit-transform: translateY(-10%);
            transform: translateY(-10%);
  }
  .reflection-container .reflection-grid-cell-71 {
    top: 70%;
    left: 0%;
  }
  .reflection-container .reflection-grid-cell-71:hover ~ .reflection-content {
    -webkit-transform: rotateX(-15deg) rotateY(-20deg);
            transform: rotateX(-15deg) rotateY(-20deg);
  }
  .reflection-container .reflection-grid-cell-71:hover ~ .reflection-content:before {
    -webkit-transform: translateY(-15%);
            transform: translateY(-15%);
  }
  .reflection-container .reflection-grid-cell-72 {
    top: 70%;
    left: 10%;
  }
  .reflection-container .reflection-grid-cell-72:hover ~ .reflection-content {
    -webkit-transform: rotateX(-15deg) rotateY(-15deg);
            transform: rotateX(-15deg) rotateY(-15deg);
  }
  .reflection-container .reflection-grid-cell-72:hover ~ .reflection-content:before {
    -webkit-transform: translateY(-15%);
            transform: translateY(-15%);
  }
  .reflection-container .reflection-grid-cell-73 {
    top: 70%;
    left: 20%;
  }
  .reflection-container .reflection-grid-cell-73:hover ~ .reflection-content {
    -webkit-transform: rotateX(-15deg) rotateY(-10deg);
            transform: rotateX(-15deg) rotateY(-10deg);
  }
  .reflection-container .reflection-grid-cell-73:hover ~ .reflection-content:before {
    -webkit-transform: translateY(-15%);
            transform: translateY(-15%);
  }
  .reflection-container .reflection-grid-cell-74 {
    top: 70%;
    left: 30%;
  }
  .reflection-container .reflection-grid-cell-74:hover ~ .reflection-content {
    -webkit-transform: rotateX(-15deg) rotateY(-5deg);
            transform: rotateX(-15deg) rotateY(-5deg);
  }
  .reflection-container .reflection-grid-cell-74:hover ~ .reflection-content:before {
    -webkit-transform: translateY(-15%);
            transform: translateY(-15%);
  }
  .reflection-container .reflection-grid-cell-75 {
    top: 70%;
    left: 40%;
  }
  .reflection-container .reflection-grid-cell-75:hover ~ .reflection-content {
    -webkit-transform: rotateX(-15deg) rotateY(0deg);
            transform: rotateX(-15deg) rotateY(0deg);
  }
  .reflection-container .reflection-grid-cell-75:hover ~ .reflection-content:before {
    -webkit-transform: translateY(-15%);
            transform: translateY(-15%);
  }
  .reflection-container .reflection-grid-cell-76 {
    top: 70%;
    left: 50%;
  }
  .reflection-container .reflection-grid-cell-76:hover ~ .reflection-content {
    -webkit-transform: rotateX(-15deg) rotateY(5deg);
            transform: rotateX(-15deg) rotateY(5deg);
  }
  .reflection-container .reflection-grid-cell-76:hover ~ .reflection-content:before {
    -webkit-transform: translateY(-15%);
            transform: translateY(-15%);
  }
  .reflection-container .reflection-grid-cell-77 {
    top: 70%;
    left: 60%;
  }
  .reflection-container .reflection-grid-cell-77:hover ~ .reflection-content {
    -webkit-transform: rotateX(-15deg) rotateY(10deg);
            transform: rotateX(-15deg) rotateY(10deg);
  }
  .reflection-container .reflection-grid-cell-77:hover ~ .reflection-content:before {
    -webkit-transform: translateY(-15%);
            transform: translateY(-15%);
  }
  .reflection-container .reflection-grid-cell-78 {
    top: 70%;
    left: 70%;
  }
  .reflection-container .reflection-grid-cell-78:hover ~ .reflection-content {
    -webkit-transform: rotateX(-15deg) rotateY(15deg);
            transform: rotateX(-15deg) rotateY(15deg);
  }
  .reflection-container .reflection-grid-cell-78:hover ~ .reflection-content:before {
    -webkit-transform: translateY(-15%);
            transform: translateY(-15%);
  }
  .reflection-container .reflection-grid-cell-79 {
    top: 70%;
    left: 80%;
  }
  .reflection-container .reflection-grid-cell-79:hover ~ .reflection-content {
    -webkit-transform: rotateX(-15deg) rotateY(20deg);
            transform: rotateX(-15deg) rotateY(20deg);
  }
  .reflection-container .reflection-grid-cell-79:hover ~ .reflection-content:before {
    -webkit-transform: translateY(-15%);
            transform: translateY(-15%);
  }
  .reflection-container .reflection-grid-cell-80 {
    top: 70%;
    left: 90%;
  }
  .reflection-container .reflection-grid-cell-80:hover ~ .reflection-content {
    -webkit-transform: rotateX(-15deg) rotateY(25deg);
            transform: rotateX(-15deg) rotateY(25deg);
  }
  .reflection-container .reflection-grid-cell-80:hover ~ .reflection-content:before {
    -webkit-transform: translateY(-15%);
            transform: translateY(-15%);
  }
  .reflection-container .reflection-grid-cell-81 {
    top: 80%;
    left: 0%;
  }
  .reflection-container .reflection-grid-cell-81:hover ~ .reflection-content {
    -webkit-transform: rotateX(-20deg) rotateY(-20deg);
            transform: rotateX(-20deg) rotateY(-20deg);
  }
  .reflection-container .reflection-grid-cell-81:hover ~ .reflection-content:before {
    -webkit-transform: translateY(-20%);
            transform: translateY(-20%);
  }
  .reflection-container .reflection-grid-cell-82 {
    top: 80%;
    left: 10%;
  }
  .reflection-container .reflection-grid-cell-82:hover ~ .reflection-content {
    -webkit-transform: rotateX(-20deg) rotateY(-15deg);
            transform: rotateX(-20deg) rotateY(-15deg);
  }
  .reflection-container .reflection-grid-cell-82:hover ~ .reflection-content:before {
    -webkit-transform: translateY(-20%);
            transform: translateY(-20%);
  }
  .reflection-container .reflection-grid-cell-83 {
    top: 80%;
    left: 20%;
  }
  .reflection-container .reflection-grid-cell-83:hover ~ .reflection-content {
    -webkit-transform: rotateX(-20deg) rotateY(-10deg);
            transform: rotateX(-20deg) rotateY(-10deg);
  }
  .reflection-container .reflection-grid-cell-83:hover ~ .reflection-content:before {
    -webkit-transform: translateY(-20%);
            transform: translateY(-20%);
  }
  .reflection-container .reflection-grid-cell-84 {
    top: 80%;
    left: 30%;
  }
  .reflection-container .reflection-grid-cell-84:hover ~ .reflection-content {
    -webkit-transform: rotateX(-20deg) rotateY(-5deg);
            transform: rotateX(-20deg) rotateY(-5deg);
  }
  .reflection-container .reflection-grid-cell-84:hover ~ .reflection-content:before {
    -webkit-transform: translateY(-20%);
            transform: translateY(-20%);
  }
  .reflection-container .reflection-grid-cell-85 {
    top: 80%;
    left: 40%;
  }
  .reflection-container .reflection-grid-cell-85:hover ~ .reflection-content {
    -webkit-transform: rotateX(-20deg) rotateY(0deg);
            transform: rotateX(-20deg) rotateY(0deg);
  }
  .reflection-container .reflection-grid-cell-85:hover ~ .reflection-content:before {
    -webkit-transform: translateY(-20%);
            transform: translateY(-20%);
  }
  .reflection-container .reflection-grid-cell-86 {
    top: 80%;
    left: 50%;
  }
  .reflection-container .reflection-grid-cell-86:hover ~ .reflection-content {
    -webkit-transform: rotateX(-20deg) rotateY(5deg);
            transform: rotateX(-20deg) rotateY(5deg);
  }
  .reflection-container .reflection-grid-cell-86:hover ~ .reflection-content:before {
    -webkit-transform: translateY(-20%);
            transform: translateY(-20%);
  }
  .reflection-container .reflection-grid-cell-87 {
    top: 80%;
    left: 60%;
  }
  .reflection-container .reflection-grid-cell-87:hover ~ .reflection-content {
    -webkit-transform: rotateX(-20deg) rotateY(10deg);
            transform: rotateX(-20deg) rotateY(10deg);
  }
  .reflection-container .reflection-grid-cell-87:hover ~ .reflection-content:before {
    -webkit-transform: translateY(-20%);
            transform: translateY(-20%);
  }
  .reflection-container .reflection-grid-cell-88 {
    top: 80%;
    left: 70%;
  }
  .reflection-container .reflection-grid-cell-88:hover ~ .reflection-content {
    -webkit-transform: rotateX(-20deg) rotateY(15deg);
            transform: rotateX(-20deg) rotateY(15deg);
  }
  .reflection-container .reflection-grid-cell-88:hover ~ .reflection-content:before {
    -webkit-transform: translateY(-20%);
            transform: translateY(-20%);
  }
  .reflection-container .reflection-grid-cell-89 {
    top: 80%;
    left: 80%;
  }
  .reflection-container .reflection-grid-cell-89:hover ~ .reflection-content {
    -webkit-transform: rotateX(-20deg) rotateY(20deg);
            transform: rotateX(-20deg) rotateY(20deg);
  }
  .reflection-container .reflection-grid-cell-89:hover ~ .reflection-content:before {
    -webkit-transform: translateY(-20%);
            transform: translateY(-20%);
  }
  .reflection-container .reflection-grid-cell-90 {
    top: 80%;
    left: 90%;
  }
  .reflection-container .reflection-grid-cell-90:hover ~ .reflection-content {
    -webkit-transform: rotateX(-20deg) rotateY(25deg);
            transform: rotateX(-20deg) rotateY(25deg);
  }
  .reflection-container .reflection-grid-cell-90:hover ~ .reflection-content:before {
    -webkit-transform: translateY(-20%);
            transform: translateY(-20%);
  }
  .reflection-container .reflection-grid-cell-91 {
    top: 90%;
    left: 0%;
  }
  .reflection-container .reflection-grid-cell-91:hover ~ .reflection-content {
    -webkit-transform: rotateX(-25deg) rotateY(-20deg);
            transform: rotateX(-25deg) rotateY(-20deg);
  }
  .reflection-container .reflection-grid-cell-91:hover ~ .reflection-content:before {
    -webkit-transform: translateY(-25%);
            transform: translateY(-25%);
  }
  .reflection-container .reflection-grid-cell-92 {
    top: 90%;
    left: 10%;
  }
  .reflection-container .reflection-grid-cell-92:hover ~ .reflection-content {
    -webkit-transform: rotateX(-25deg) rotateY(-15deg);
            transform: rotateX(-25deg) rotateY(-15deg);
  }
  .reflection-container .reflection-grid-cell-92:hover ~ .reflection-content:before {
    -webkit-transform: translateY(-25%);
            transform: translateY(-25%);
  }
  .reflection-container .reflection-grid-cell-93 {
    top: 90%;
    left: 20%;
  }
  .reflection-container .reflection-grid-cell-93:hover ~ .reflection-content {
    -webkit-transform: rotateX(-25deg) rotateY(-10deg);
            transform: rotateX(-25deg) rotateY(-10deg);
  }
  .reflection-container .reflection-grid-cell-93:hover ~ .reflection-content:before {
    -webkit-transform: translateY(-25%);
            transform: translateY(-25%);
  }
  .reflection-container .reflection-grid-cell-94 {
    top: 90%;
    left: 30%;
  }
  .reflection-container .reflection-grid-cell-94:hover ~ .reflection-content {
    -webkit-transform: rotateX(-25deg) rotateY(-5deg);
            transform: rotateX(-25deg) rotateY(-5deg);
  }
  .reflection-container .reflection-grid-cell-94:hover ~ .reflection-content:before {
    -webkit-transform: translateY(-25%);
            transform: translateY(-25%);
  }
  .reflection-container .reflection-grid-cell-95 {
    top: 90%;
    left: 40%;
  }
  .reflection-container .reflection-grid-cell-95:hover ~ .reflection-content {
    -webkit-transform: rotateX(-25deg) rotateY(0deg);
            transform: rotateX(-25deg) rotateY(0deg);
  }
  .reflection-container .reflection-grid-cell-95:hover ~ .reflection-content:before {
    -webkit-transform: translateY(-25%);
            transform: translateY(-25%);
  }
  .reflection-container .reflection-grid-cell-96 {
    top: 90%;
    left: 50%;
  }
  .reflection-container .reflection-grid-cell-96:hover ~ .reflection-content {
    -webkit-transform: rotateX(-25deg) rotateY(5deg);
            transform: rotateX(-25deg) rotateY(5deg);
  }
  .reflection-container .reflection-grid-cell-96:hover ~ .reflection-content:before {
    -webkit-transform: translateY(-25%);
            transform: translateY(-25%);
  }
  .reflection-container .reflection-grid-cell-97 {
    top: 90%;
    left: 60%;
  }
  .reflection-container .reflection-grid-cell-97:hover ~ .reflection-content {
    -webkit-transform: rotateX(-25deg) rotateY(10deg);
            transform: rotateX(-25deg) rotateY(10deg);
  }
  .reflection-container .reflection-grid-cell-97:hover ~ .reflection-content:before {
    -webkit-transform: translateY(-25%);
            transform: translateY(-25%);
  }
  .reflection-container .reflection-grid-cell-98 {
    top: 90%;
    left: 70%;
  }
  .reflection-container .reflection-grid-cell-98:hover ~ .reflection-content {
    -webkit-transform: rotateX(-25deg) rotateY(15deg);
            transform: rotateX(-25deg) rotateY(15deg);
  }
  .reflection-container .reflection-grid-cell-98:hover ~ .reflection-content:before {
    -webkit-transform: translateY(-25%);
            transform: translateY(-25%);
  }
  .reflection-container .reflection-grid-cell-99 {
    top: 90%;
    left: 80%;
  }
  .reflection-container .reflection-grid-cell-99:hover ~ .reflection-content {
    -webkit-transform: rotateX(-25deg) rotateY(20deg);
            transform: rotateX(-25deg) rotateY(20deg);
  }
  .reflection-container .reflection-grid-cell-99:hover ~ .reflection-content:before {
    -webkit-transform: translateY(-25%);
            transform: translateY(-25%);
  }
  .reflection-container .reflection-grid-cell-100 {
    top: 90%;
    left: 90%;
  }
  .reflection-container .reflection-grid-cell-100:hover ~ .reflection-content {
    -webkit-transform: rotateX(-25deg) rotateY(25deg);
            transform: rotateX(-25deg) rotateY(25deg);
  }
  .reflection-container .reflection-grid-cell-100:hover ~ .reflection-content:before {
    -webkit-transform: translateY(-25%);
            transform: translateY(-25%);
  }
.hidden {position:absolute;visibility:hidden;opacity:0;}

.modal-dialog{
  margin: 72px auto !important;
}
.MuiTimelineItem-content p{
  word-break: break-all !important;
}
.my-masonry-grid { /* Not needed if autoprefixing */ /* Not needed if autoprefixing */
  display: flex;
  margin-left: -15px; /* gutter size offset */
  width: auto;
}
.my-masonry-grid_column {
  padding-left: 15px; /* gutter size */
  background-clip: padding-box;
}

/* Style your items */
.my-masonry-grid_column > div { /* change div to reference your elements you put in <Masonry> */
  /* background: grey; */
  margin-bottom: 15px!important;
}

input[type=radio]{
  display: inline-block !important;
}
.sel{
  color: red!important;
}
.App {
  text-align: center;
}

.active{
  font-weight: bolder;
}

.nav-link{
  margin-left: 15px;
  margin-right: 15px;
  opacity: 0.8;
}

*:focus {
  outline: none;
}

/* stylelint-disable selector-list-comma-newline-after */

.blog-header {
  line-height: 1;
  border-bottom: 1px solid #e5e5e5;
}

.blog-header-logo {
  font-family: "Playfair Display", Georgia, "Times New Roman", serif;
  font-size: 2.25rem;
}

.blog-header-logo:hover {
  text-decoration: none;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "Playfair Display", Georgia, "Times New Roman", serif;
}

.display-4 {
  font-size: 2.5rem;
}
@media (max-width: 768px) {
  .display-4 {
    font-size: 3rem;
  }
}

.nav-scroller {
  position: relative;
  z-index: 2;
  height: 2.75rem;
  overflow-y: hidden;
}

.nav-scroller .nav {
  display: flex;
  flex-wrap: nowrap;
  padding-bottom: 1rem;
  margin-top: -1px;
  overflow-x: auto;
  text-align: center;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
}

.nav-scroller .nav-link {
  padding-top: .75rem;
  padding-bottom: .75rem;
  font-size: .875rem;
}

.card-img-right {
  height: 100%;
  border-radius: 0 3px 3px 0;
}

.flex-auto {
  flex: 0 0 auto;
}

.h-250 { height: 250px; }
@media (max-width: 768px) {
  .h-md-250 { height: 250px; }
}

/* Pagination */
.blog-pagination {
  margin-bottom: 4rem;
}
.blog-pagination > .btn {
  border-radius: 2rem;
}

/*
 * Blog posts
 */
.blog-post {
  margin-bottom: 4rem;
}
.blog-post-title {
  margin-bottom: .25rem;
  font-size: 2.5rem;
}
.blog-post-meta {
  margin-bottom: 1.25rem;
  color: #999;
}

/*
 * Footer
 */
.blog-footer {
  padding: 0.5rem 0;
  color: #999;
  text-align: center;
  background-color: #f9f9f9;
  border-top: .05rem solid #ddd5;
}
.blog-footer p:last-child {
  margin-bottom: 0;
}
.rishaFeed{
  /* height: 100vh!important;
   */
   text-align: left;
  overflow: hidden;
  padding: 0px 15px;
}
.card-header{
  background-color: transparent!important;
  border: none!important;
}
.preserve
{
    text-decoration: none !important;
    border-bottom: 1px solid #ccc;
    color: inherit;
    cursor: pointer;
}

a:hover{
  text-decoration: none!important;
}
.preserveb
{
    text-decoration: none !important;
    color: inherit;
    cursor: pointer;
}

.unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.clickable{
  cursor: pointer;
}

@media screen and (max-width: 992px)
{
  .lab-member {
    grid-template-columns: 1fr 1fr 1fr 1fr !important;
  }

}
@media screen and (max-width: 769px)
{
  .lab-member {
    grid-template-columns: 1fr 1fr 1fr !important;
  }

}

@media screen and (max-width: 350px)
{
  .lab-member {
    grid-template-columns: 1fr 1fr !important;
  }

}
.lab-member {
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
}

.card{
  border-radius: 4px;
  background: #fff;
  box-shadow: 0 6px 10px rgba(0,0,0,.08), 0 0 6px rgba(0,0,0,.05);
    transition: .3s transform cubic-bezier(.155,1.105,.295,1.12),.3s box-shadow,.3s -webkit-transform cubic-bezier(.155,1.105,.295,1.12);
padding: 14px 80px 18px 36px;
cursor: pointer;
}

.card:hover{
   -webkit-transform: scale(1.05);
           transform: scale(1.05);
box-shadow: 0 10px 20px rgba(0,0,0,.12), 0 4px 8px rgba(0,0,0,.06);
}

.specialModal{
  background-color: rgba(0,0,0,0.5) !important;
  opacity: 1!important;
  /* -webkit-animation: fadein 0.5s; 
  -moz-animation: fadein 0.5s;
   -ms-animation: fadein 0.5s;
    -o-animation: fadein 0.5s;
       animation: fadein 0.5s; */
}
.animated-wrapper > div:not([style*="opacity: 1"]) > .specialModal{
  pointer-events: none;
}
@keyframes fadein {
from { opacity: 0; }
to   { opacity: 1; }
}

/* Firefox < 16 */

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
from { opacity: 0; }
to   { opacity: 1; }
}

/* Internet Explorer */

/* Opera < 12.1 */

.img-wrap:hover{
  opacity: 0.9;
}


.cf {
  max-width: 600px;
  text-align: center;
  margin: 20px auto;
}
.cf input, .cf textarea {
  border: 0;
  outline: 0;
  padding: 1em;
  border-radius: 8px;
  display: block;
  width: 100%;
  margin-top: 1em;
  font-family: 'Merriweather', sans-serif;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  resize: none;
}
.cf input:focus, .cf textarea:focus {
  box-shadow: 0 0px 2px #e74c3c !important;
}
/* .cf #input-submit {
  color: white;
  transition: all 0.3s;
  background: #a8a8a8;
  cursor: pointer;
}
.cf #input-submit:hover {
  background: #e74c3c;
  -moz-box-shadow: 0 1px 1px 1px rgba(170, 170, 170, 0.6);
  -webkit-box-shadow: 0 1px 1px 1px rgba(170, 170, 170, 0.6);
  box-shadow: 0 1px 1px 1px rgba(170, 170, 170, 0.6);
} */
.cf textarea {
  height: 12.5em;
}

.half {
  float: left;
  width: 48%;
  margin-bottom: 1em;
}

.right {
  width: 50%;
}

.left {
  margin-right: 2%;
}

@media (max-width: 480px) {
  .half {
    width: 100%;
    float: none;
    margin-bottom: 0;
  }
}
/* Clearfix */
.cf:before,
.cf:after {
  content: " ";
  /* 1 */
  display: table;
  /* 2 */
}

.cf:after {
  clear: both;
}

.cfh {
  font-family: "Merriweather", sans-serif;
  text-align: center;
  color: #a8a8a8;
}
